import {
  BoxProps,
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { onboardUser } from "../../../DataAccess/users";
import {
  selectUserState,
  updateUserState,
} from "../../../features/user/userSlice";
import { createToast } from "../../../utils/toastHelper";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../context/BreadcrumbContext";
import { CreateSupportTicket } from "../../ui/CreateSupportTicket";
import { CustomToast } from "../../ui/CustomToast";
import {
  ActivityTypeSelector,
  CountrySelector,
  GenderSelector,
  LaneSelector,
  LevelSelector,
  PoolLengthSelector,
  PoolUnitSelector,
  SwimTypeSelector,
  TrainingTypeSelector,
} from "../../ui/DropDowns";
import Loading from "../../ui/Loading";

interface OnboardingProps extends BoxProps {}

const Onboarding: React.FC<OnboardingProps> = () => {
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const toast = useToast();
  const { user } = useAppSelector(selectUserState);
  const [isOnboarding, setIsOnboarding] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const {
    control: controlOnboarding,
    setValue: setValueOnboarding,
    handleSubmit: handleSubmitOnboarding,
    trigger: triggerOnboarding,
    watch: watchOnboarding,
    formState: { errors: errorsOnboarding, isValid: isValidOnboarding },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      gender: "U",
      country: "",
      swimType: "U",
      activityType: "U",
      trainingType: "U",
      level: "U",
      lane: -1,
      lapLength: 50,
      lapUnit: "m",
    },
    mode: "all",
  });

  useEffect(() => {
    const breadcrumbLinks = [{ href: "/", title: "Onboarding" }];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      setValueOnboarding(
        "firstName",
        user.data.basic.firstName ? user.data.basic.firstName : ""
      );
      setValueOnboarding(
        "lastName",
        user.data.basic.lastName ? user.data.basic.lastName : ""
      );
      setValueOnboarding(
        "gender",
        user.data.basic.gender ? user.data.basic.gender : "U"
      );
      setValueOnboarding(
        "country",
        user.data.basic.country ? user.data.basic.country : "US"
      );
      setValueOnboarding(
        "swimType",
        user.data.basic.swimType ? user.data.basic.swimType : "U"
      );
      setValueOnboarding(
        "activityType",
        user.data.basic.activityType ? user.data.basic.activityType : "U"
      );
      setValueOnboarding(
        "trainingType",
        user.data.basic.trainingType ? user.data.basic.trainingType : "U"
      );
      setValueOnboarding(
        "level",
        user.data.basic.level ? user.data.basic.level : "U"
      );
      setValueOnboarding(
        "lane",
        user.data.basic.lane ? user.data.basic.lane : -1
      );
      setValueOnboarding(
        "lapLength",
        user.data.basic.defaultPool.lapLength
          ? user.data.basic.defaultPool.lapLength
          : 50
      );
      setValueOnboarding(
        "lapUnit",
        user.data.basic.defaultPool.lapUnit
          ? user.data.basic.defaultPool.lapUnit
          : "m"
      );
      triggerOnboarding();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleOnboarding = async (data: any): Promise<void> => {
    toast.closeAll();
    setIsOnboarding(true);
    if (user) {
      try {
        const updatedUser = await onboardUser({
          firstName: data.firstName,
          lastName: data.lastName,
          gender: data.gender,
          country: data.country,
          swimType: data.swimType,
          activityType: data.activityType,
          trainingType: data.trainingType,
          level: data.level,
          lane: parseInt(data.lane, 10),
          lapLength: parseInt(data.lapLength, 10),
          lapUnit: data.lapUnit,
        });
        dispatch(updateUserState(updatedUser));
        setTimeout(function () {
          window.location.reload();
        }, 50);
      } catch (error: any) {
        if (user) {
          setValueOnboarding("firstName", user.data.basic.firstName);
          setValueOnboarding("lastName", user.data.basic.lastName);
          setValueOnboarding("gender", user.data.basic.gender);
          setValueOnboarding("country", user.data.basic.country);
          setValueOnboarding("swimType", user.data.basic.swimType);
          setValueOnboarding("activityType", user.data.basic.swimType);
          setValueOnboarding("trainingType", user.data.basic.swimType);
          setValueOnboarding("level", user.data.basic.level);
          setValueOnboarding("lane", user.data.basic.lane);
          setValueOnboarding(
            "lapLength",
            user.data.basic.defaultPool.lapLength
          );
          setValueOnboarding("lapUnit", user.data.basic.defaultPool.lapUnit);
        }
        createToast(toast, (props: any) => {
          return (
            <CustomToast
              title={"Onboarding"}
              status={"Error"}
              toast={toast}
              toastId={props.id}
            >
              <Text>Unable to onboard user, please contact support.</Text>
              <CreateSupportTicket />
            </CustomToast>
          );
        });
      }
    }
  };

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  const watchLapUnit = watchOnboarding("lapUnit", "m");

  return (
    <VStack
      as="form"
      onSubmit={handleSubmitOnboarding(handleOnboarding)}
      w="full"
      alignItems="flex-start"
    >
      {(!user.processes?.onboardingLastDate ||
        (user.processes.onboardingLastDate &&
          new Date(user.processes.onboardingLastDate).getFullYear() <=
            1970)) && (
        <>
          <Heading>
            Hey {user.data.basic.firstName}, let's get you started!
          </Heading>
          <Text pb={4}>
            We have <em>Ten</em> quick questions for you that will help us to
            help you improve your swimming. If you don't know the answers then
            don't worry too much but the more we know about you and your
            swimming the more accurate we can be and the more improvement you
            will see. Every so often we'll ask you these questions again to make
            sure they still reflect you and your swimming.
          </Text>
        </>
      )}
      {user.processes?.onboardingLastDate &&
        new Date(user.processes.onboardingLastDate).getFullYear() > 1970 && (
          <>
            <Heading>
              Hey {user.data.basic.firstName}, it's been a while since we
              checked in on your settings.
            </Heading>
            <Text pb={4}>
              Over time things change, {user.data.basic.firstName}, and we just
              want to check in on the <em>five</em> quick questions that we
              asked you previously. If nothing has changed then there is nothing
              to do but if things have moved on then updating the answers will
              help us to better help you.
            </Text>
          </>
        )}
      <SimpleGrid columns={[1, 1, 2, 2]} columnGap={5} rowGap={2} w="full">
        <VStack w="full" alignItems="flex-start">
          <FormControl pb={1}>
            <FormLabel>
              1. <em>What</em> is your <em>first name</em>?
              <strong>
                <sup>1</sup>
              </strong>
              {errorsOnboarding.firstName && (
                <span className="formError">
                  {errorsOnboarding.firstName.message}
                </span>
              )}
            </FormLabel>
            <Controller
              control={controlOnboarding}
              rules={{ required: "First name is required" }}
              name="firstName"
              render={({ field: { ref, ...restField } }) => (
                <Input {...restField} placeholder="" />
              )}
            />
          </FormControl>
          <FormControl pb={1}>
            <FormLabel>
              2. <em>What</em> is your <em>last name</em>?
              <strong>
                <sup>1</sup>
              </strong>
              {errorsOnboarding.lastName && (
                <span className="formError">
                  {errorsOnboarding.lastName.message}
                </span>
              )}
            </FormLabel>
            <Controller
              control={controlOnboarding}
              rules={{ required: "Last name is required" }}
              name="lastName"
              render={({ field: { ref, ...restField } }) => (
                <Input {...restField} placeholder="" />
              )}
            />
          </FormControl>
          <FormControl pb={1}>
            <FormLabel>
              3. <em>What</em> is your <em>gender</em>?
              <strong>
                <sup>1</sup>
              </strong>
              {errorsOnboarding.gender && (
                <span className="formError">
                  {errorsOnboarding.gender.message}
                </span>
              )}
            </FormLabel>
            <Controller
              control={controlOnboarding}
              rules={{ required: "Gender is required" }}
              name="gender"
              render={({ field: { ref, onChange, ...restField } }) => (
                <GenderSelector
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(e)
                  }
                  {...restField}
                  placeholder=""
                />
              )}
            />
          </FormControl>
          <FormControl pb={1}>
            <FormLabel>
              4. <em>What country</em> do you live in?
              <strong>
                <sup>1</sup>
              </strong>
              {errorsOnboarding.country && (
                <span className="formError">
                  {errorsOnboarding.country.message}
                </span>
              )}
            </FormLabel>
            <Controller
              control={controlOnboarding}
              rules={{ required: "Country is required" }}
              name="country"
              render={({ field: { ref, onChange, ...restField } }) => (
                <CountrySelector
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(e)
                  }
                  {...restField}
                  placeholder=""
                />
              )}
            />
          </FormControl>
          <FormControl pb={1}>
            <FormLabel>
              5. <em>What</em> length pool do you <em>usually</em> swim in?
              <strong>
                <sup>1</sup>
              </strong>
              {errorsOnboarding.lapLength && (
                <span className="formError">
                  {errorsOnboarding.lapLength.message}
                </span>
              )}
              {errorsOnboarding.lapUnit && (
                <span className="formError">
                  {errorsOnboarding.lapUnit.message}
                </span>
              )}
            </FormLabel>
            <HStack w="full" alignItems="flex-start">
              <Controller
                control={controlOnboarding}
                rules={{ required: "Pool Length is required" }}
                name="lapLength"
                render={({ field: { ref, onChange, ...restField } }) => (
                  <PoolLengthSelector
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onChange(e)
                    }
                    {...restField}
                    placeholder=""
                  />
                )}
              />
              <Controller
                control={controlOnboarding}
                rules={{ required: "Pool Unit is required" }}
                name="lapUnit"
                render={({ field: { ref, onChange, ...restField } }) => (
                  <PoolUnitSelector
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onChange(e)
                    }
                    {...restField}
                    placeholder=""
                  />
                )}
              />
            </HStack>
          </FormControl>
        </VStack>
        <VStack w="full" alignItems="flex-start">
          <FormControl pb={1}>
            <FormLabel>
              6. <em>Which</em> type of swimmer do you <em>most</em> identify
              with?
              {errorsOnboarding.activityType && (
                <span className="formError">
                  {errorsOnboarding.activityType.message}
                </span>
              )}
            </FormLabel>
            <Controller
              control={controlOnboarding}
              name="activityType"
              render={({ field: { ref, onChange, ...restField } }) => (
                <ActivityTypeSelector
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(e)
                  }
                  {...restField}
                  placeholder=""
                />
              )}
            />
          </FormControl>
          <FormControl pb={1}>
            <FormLabel>
              7. <em>Why</em> do you swim / coach?
              {errorsOnboarding.trainingType && (
                <span className="formError">
                  {errorsOnboarding.trainingType.message}
                </span>
              )}
            </FormLabel>
            <Controller
              control={controlOnboarding}
              name="trainingType"
              render={({ field: { ref, onChange, ...restField } }) => (
                <TrainingTypeSelector
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(e)
                  }
                  {...restField}
                  placeholder=""
                />
              )}
            />
          </FormControl>
          <FormControl pb={1}>
            <FormLabel>
              8. <em>What</em> level of swimmer would you say you are?{" "}
              <strong>
                <sup>2</sup>
              </strong>
              {errorsOnboarding.level && (
                <span className="formError">
                  {errorsOnboarding.level.message}
                </span>
              )}
            </FormLabel>
            <Controller
              control={controlOnboarding}
              name="level"
              render={({ field: { ref, onChange, ...restField } }) => (
                <LevelSelector
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(e)
                  }
                  {...restField}
                  placeholder=""
                />
              )}
            />
          </FormControl>
          <FormControl pb={1}>
            <FormLabel>
              9. <em>What</em> is your Swim Smooth swim type?
              {errorsOnboarding.swimType && (
                <span className="formError">
                  {errorsOnboarding.swimType.message}
                </span>
              )}
            </FormLabel>
            <Controller
              control={controlOnboarding}
              name="swimType"
              render={({ field: { ref, onChange, ...restField } }) => (
                <SwimTypeSelector
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(e)
                  }
                  {...restField}
                  placeholder=""
                />
              )}
            />
          </FormControl>
          <FormControl pb={1}>
            <FormLabel>
              10. <em>How</em> fast do you swim?{" "}
              <strong>
                <sup>2,3</sup>
              </strong>
              {errorsOnboarding.lane && (
                <span className="formError">
                  {errorsOnboarding.lane.message}
                </span>
              )}
            </FormLabel>
            <Controller
              control={controlOnboarding}
              name="lane"
              render={({ field: { ref, onChange, ...restField } }) => (
                <LaneSelector
                  unit={watchLapUnit}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(e)
                  }
                  {...restField}
                  placeholder={-1}
                />
              )}
            />
          </FormControl>
        </VStack>
      </SimpleGrid>
      <HStack w="full" alignItems={"flex-start"} pt={2}>
        <VStack w={"full"} alignItems={"flex-start"} mr={20}>
          <Text>
            <strong>Note 1</strong>: This data is required to personalise the
            recommendations we make. It will never be sold or passed on in any
            way shape or form to any third parties other than your name which
            may be used for billing purposes.
          </Text>
          <Text>
            <strong>Note 2</strong>: We may update these values when you update
            your CSS or based on your performance in activities recorded with
            the Swim Smooth watch app.
          </Text>
          <Text>
            <strong>Note 3</strong>: We often ask new swimmers this question
            when they first arrive on the pool deck. It helps us tailor the
            session to their needs and put them with swimmers of a similar pace.
            If you know your CSS pace you can select the right 'lane' based on
            that. If not think about roughly how fast you could swim each 100m
            of a 15 x 100m set and use that time to select your 'lane'. It
            doesn't need to be super precise we're only going to use it a guide.
          </Text>
        </VStack>
        {(!user.processes?.onboardingLastDate ||
          (user.processes.onboardingLastDate &&
            new Date(user.processes.onboardingLastDate).getFullYear() <=
              1970)) && (
          <Button
            isDisabled={!isValidOnboarding}
            isLoading={isOnboarding}
            type="submit"
            px={20}
          >
            Save and get started --&gt;
          </Button>
        )}
        {user.processes?.onboardingLastDate &&
          new Date(user.processes.onboardingLastDate).getFullYear() > 1970 && (
            <Button
              isDisabled={!isValidOnboarding}
              isLoading={isOnboarding}
              type="submit"
              mt={5}
            >
              Update and carry on --&gt;
            </Button>
          )}
      </HStack>
    </VStack>
  );
};

export { Onboarding };
