import {
  Button,
  FormControl,
  Heading,
  HStack,
  Input,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { updateEmailAddress } from "../../../DataAccess/users";
import {
  selectUserState,
  updateUserState,
} from "../../../features/user/userSlice";
import { createToast } from "../../../utils/toastHelper";
import { CreateSupportTicket } from "../../ui/CreateSupportTicket";
import { CustomToast } from "../../ui/CustomToast";
import Loading from "../../ui/Loading";

const AccountSettingsEmailAddress = () => {
  const toast = useToast();

  const { user } = useAppSelector(selectUserState);
  const dispatch = useAppDispatch();

  const {
    control: controlBasic,
    reset: resetBasic,
    setValue: setValueBasic,
    handleSubmit: handleSubmitBasic,
    formState: {
      errors: errorsBasic,
      isSubmitting: isSubmittingBasic,
      isValid: isValidBasic,
      isDirty: isDirtyBasic,
    },
  } = useForm({
    defaultValues: {
      emailAddress: "",
    },
    mode: "all",
  });

  useEffect(() => {
    if (user) {
      setValueBasic("emailAddress", user.data.basic.emailAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleBasic = async (data: any): Promise<void> => {
    toast.closeAll();
    if (user) {
      try {
        const updatedUser = await updateEmailAddress(user._id, {
          emailAddress: data.emailAddress,
        });
        createToast(toast, (props: any) => {
          return (
            <CustomToast
              title={"Update Email Address"}
              status={"Success"}
              toast={toast}
              toastId={props.id}
            >
              <Text>
                Your email address has been succesfully updated. From now on
                your will need to login with your new email address (
                {data.emailAddress}).
              </Text>
            </CustomToast>
          );
        });
        dispatch(updateUserState(updatedUser));
        resetBasic(data);
      } catch (error: any) {
        console.log(error);
        if (user) {
          setValueBasic("emailAddress", user.data.basic.emailAddress);
        }
        createToast(toast, (props: any) => {
          return (
            <CustomToast
              title={"Account Settings"}
              status={"Error"}
              toast={toast}
              toastId={props.id}
            >
              <Text>
                {error && error.message
                  ? error.message
                  : "Unable to change email address, please contact support."}
              </Text>
              <CreateSupportTicket />
            </CustomToast>
          );
        });
      }
    }
  };

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  return (
    <VStack
      as="form"
      onSubmit={handleSubmitBasic(handleBasic)}
      w="full"
      alignItems="flex-start"
      mb={5}
    >
      <Heading as="h3" size="md">
        Login Email Address
      </Heading>
      <HStack w="full">
        <FormControl>
          <Controller
            control={controlBasic}
            rules={{ required: "Email address is required" }}
            name="emailAddress"
            render={({ field: { ref, ...restField } }) => (
              <Input {...restField} placeholder="" />
            )}
          />
        </FormControl>
        <Button
          isDisabled={!isDirtyBasic || !isValidBasic}
          isLoading={isSubmittingBasic}
          type="submit"
          px={10}
        >
          Change Email Address
        </Button>
      </HStack>
      {errorsBasic.emailAddress && (
        <span className="formError">{errorsBasic.emailAddress.message}</span>
      )}
    </VStack>
  );
};

export { AccountSettingsEmailAddress };
