import axios from "axios";
import { processSwimSmoothApiError } from "../components/generic/ErrorProcessing";

const login = async (
  email: string,
  password: string,
  hToken: string
): Promise<any> => {
  try {
    const loginResponse = await axios.post(
      process.env.REACT_APP_API_ROOT_URL + "oauth/token",
      {
        grant_type: "password",
        username: email,
        password: password,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        hToken: hToken,
        captchaSource: "H",
      }
    );
    return loginResponse.data;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error.response.data));
    } else {
      throw error;
    }
  }
};

const refresh = async (refreshToken: string): Promise<any> => {
  try {
    return await axios.post(
      process.env.REACT_APP_API_ROOT_URL + "oauth/refresh",
      {
        refresh_token: refreshToken,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
      }
    );
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error.response.data));
    } else {
      throw error;
    }
  }
};

const swap = async (alternate: string): Promise<any> => {
  try {
    const swapResponse = await axios.post(
      process.env.REACT_APP_API_ROOT_URL + "oauth/swap",
      {
        alternate: alternate,
      }
    );
    return swapResponse.data;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error.response.data));
    } else {
      throw error;
    }
  }
};

export { login, refresh, swap };
