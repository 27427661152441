import axios from "axios";
import { processSwimSmoothApiError } from "../components/generic/ErrorProcessing";
import { relationship } from "../types/relationship";

const createRelationship = async (
  recipientName: string,
  recipientEmailAddress: string,
  routineDays: number,
  understandingDays: number,
  upgradeToPremiumMailingList: string
): Promise<relationship> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "relationships";
    const postData = {
      recipientName: recipientName,
      recipientEmailAddress: recipientEmailAddress,
      routineDays: routineDays,
      understandingDays: understandingDays,
      upgradeToPremiumMailingList: upgradeToPremiumMailingList,
    };
    const relationshipsResponse = await axios.post(url, postData);
    return relationshipsResponse.data.payload;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error.response.data));
    } else {
      throw error;
    }
  }
};

const changeRelationshipTrial = async (
  relationshipId: string,
  level: string,
  addDays: number
): Promise<relationship> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "relationships/trial";
    const postData = {
      relationshipId: relationshipId,
      level: level,
      addDays: addDays,
    };
    const relationshipsResponse = await axios.patch(url, postData);
    return relationshipsResponse.data.payload;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error.response.data));
    } else {
      throw error;
    }
  }
};

const deleteRelationship = async (relationshipId: string): Promise<void> => {
  try {
    let url =
      process.env.REACT_APP_API_ROOT_URL + "relationships/" + relationshipId;
    await axios.delete(url);
    return;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error.response.data));
    } else {
      throw error;
    }
  }
};

const getRelationshipsForUser = async (
  userId: string
): Promise<relationship[]> => {
  try {
    let url =
      process.env.REACT_APP_API_ROOT_URL + "relationships/user/" + userId;
    const relationshipsResponse = await axios.get(url);
    return relationshipsResponse.data.payload;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error.response.data));
    } else {
      throw error;
    }
  }
};

export {
  changeRelationshipTrial,
  createRelationship,
  deleteRelationship,
  getRelationshipsForUser,
};
