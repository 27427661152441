import {
  BoxProps,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { affiliate } from "../../types/affiliate";
import { relationship } from "../../types/relationship";
import { RelationshipItem } from "./RelationshipsItem";

interface LinkedUsersProps extends BoxProps {
  affiliate: affiliate;
  relationships: relationship[];
}

const LinkedUsers: React.FC<LinkedUsersProps> = ({ relationships }) => {
  return (
    <TableContainer mb={10} w={"full"}>
      <Table size="sm" w={"full"}>
        <Thead>
          <Tr>
            <Th>First Name</Th>
            <Th>Last Name</Th>
            <Th>Email</Th>
            <Th>Routine</Th>
            <Th></Th>
            <Th>Understanding</Th>
            <Th></Th>
            <Th>Subscription</Th>
            <Th isNumeric>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {relationships.length < 1 && (
            <Tr>
              <Td colSpan={10} textAlign={"center"} fontWeight={"bold"} py={10}>
                No Linked Users Found
              </Td>
            </Tr>
          )}
          {relationships.map((r: relationship) => {
            return <RelationshipItem key={r._id} relationship={r} />;
          })}
        </Tbody>
        <Tfoot>
          <Tr>
            <Th>First Name</Th>
            <Th>Last Name</Th>
            <Th>Email</Th>
            <Th>Routine</Th>
            <Th></Th>
            <Th>Understanding</Th>
            <Th></Th>
            <Th>Subscription</Th>
            <Th isNumeric>Actions</Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

export { LinkedUsers };
