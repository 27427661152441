import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserById } from "../../DataAccess/users";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectAuthState,
  updateAuthState,
} from "../../features/auth/authSlice";
import {
  selectUserState,
  updateUserState,
} from "../../features/user/userSlice";
import { checkToken } from "../../utils/authHelper";
import { Onboarding } from "../account/onboarding/Onboarding";
import Loading from "../ui/Loading";

export type ProtectedRouteProps = {
  authenticationPath: string;
  outlet: JSX.Element;
};

const ProtectedRoute = ({
  authenticationPath,
  outlet,
}: ProtectedRouteProps) => {
  const navigate = useNavigate();
  const [authorisationChecked, setAuthorisationChecked] =
    useState<boolean>(false);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [onboarded, setOnboarded] = useState<boolean>(false);
  const [loadingOnboarding, setLoadingOnboarding] = useState<boolean>(true);
  const { user, lastUpdated: lastUpdatedUser } =
    useAppSelector(selectUserState);
  const dispatch = useAppDispatch();
  const { access: accessToken, refresh: refreshToken } =
    useAppSelector(selectAuthState);

  // Save the requested url so that we can forward there after login if needed
  const requestedLocation = useLocation();

  useEffect(() => {
    const checkTokenLocal = async () => {
      try {
        const authCheckResponse = await checkToken(accessToken, refreshToken);
        // Write tokens and user state
        if (authCheckResponse && authCheckResponse.authenticated) {
          if (authCheckResponse.updated) {
            dispatch(
              updateAuthState({
                access: authCheckResponse.accessToken,
                refresh: authCheckResponse.refreshToken,
                admin: null,
                userId: authCheckResponse.userId,
              })
            );
          }
          const test = new Date(lastUpdatedUser).getTime();
          const anHourAgo = new Date().getTime() - 1000 * 60 * 60;
          if (!user || anHourAgo > test) {
            const userResponse = await getUserById(authCheckResponse.userId);
            dispatch(updateUserState(userResponse));
          }
        } else {
          navigate("/", { replace: false });
        }
        setAuthenticated(authCheckResponse.authenticated);
        if (!authCheckResponse.authenticated) {
          navigate(authenticationPath, {
            replace: false,
            state: { requestedLocation },
          });
        }
      } catch (error: any) {
        console.log("Protected Route Catch", error.message);
      }
    };
    checkTokenLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && user.processes && user.processes.onboardingLastDate) {
      const onboardingDate = new Date(user.processes.onboardingLastDate);
      const sixMonthsAgo = new Date();
      sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
      if (onboardingDate <= sixMonthsAgo) {
        setOnboarded(false);
      } else {
        setOnboarded(true);
      }
    } else {
      setOnboarded(false);
    }
    setAuthorisationChecked(true);
    setLoadingOnboarding(false);
  }, [user]);

  if (!authorisationChecked || !authenticated) {
    return <Loading message="Authenticating User" />;
  }

  if (loadingOnboarding) {
    return <Loading message="Checking User" />;
  }

  if (!user) {
    return <Loading message="Error Loading User - Protected Route" />;
  }

  if (authenticated && !onboarded) {
    return <Onboarding />;
  }

  if (authenticated) {
    return outlet;
  }

  return null;
};

export { ProtectedRoute };
