import {
  Box,
  Container,
  Flex,
  HStack,
  SimpleGrid,
  Text,
  useMediaQuery,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import { latestVersion } from "../../pages/versioning/ReleaseLog";
import { ButtonNavigate } from "../ui/FormControls";

const Footer: React.FC = () => {
  const stylesFloat = useMultiStyleConfig("Footer", {});
  const [hamburgerMode] = useMediaQuery("(max-width: 768px)");

  if (hamburgerMode) {
    return (
      <Container
        maxW={["container.sm", "container.md", "container.lg", "container.xl"]}
        w="100%"
        p="0"
      >
        <SimpleGrid w="full" columns={1}>
          <ButtonNavigate route="/coaches" variant="link">
            Find a Coach
          </ButtonNavigate>
          <ButtonNavigate route="/terms" variant="link">
            Terms of Service
          </ButtonNavigate>
          <ButtonNavigate route="/privacy" variant="link">
            Privacy Policy
          </ButtonNavigate>
          <ButtonNavigate route="/releases/log" variant="link">
            Release Log
          </ButtonNavigate>
          <Text align="center">{latestVersion}</Text>
        </SimpleGrid>
        <Box pb={5}>
          <Text fontSize={"smaller"} align="center" color={"gray.500"}>
            &copy; Swim Smooth Limited 2004 - {new Date().getFullYear()}
          </Text>
        </Box>
      </Container>
    );
  }

  return (
    <Flex __css={{ ...stylesFloat.main }}>
      <Container
        maxW={["container.sm", "container.md", "container.lg", "container.xl"]}
        w="100%"
        p="0"
      >
        <SimpleGrid w="full" columns={[1, 1, 2, 2]}>
          <HStack w="full">
            <ButtonNavigate route="/coaches" variant="link">
              Find a Coach
            </ButtonNavigate>{" "}
            <Text>|</Text>
            <ButtonNavigate route="/terms" variant="link">
              Terms of Service
            </ButtonNavigate>{" "}
            <Text>|</Text>
            <ButtonNavigate route="/privacy" variant="link">
              Privacy Policy
            </ButtonNavigate>
            <Text>|</Text>
            <ButtonNavigate route="/releases/log" variant="link">
              {latestVersion}
            </ButtonNavigate>
          </HStack>
          <Box>
            <Text align={["center", "center", "right"]}>
              &copy; Swim Smooth Limited 2004 - {new Date().getFullYear()}
            </Text>
          </Box>
        </SimpleGrid>
      </Container>
    </Flex>
  );
};

export { Footer };
