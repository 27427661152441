import {
  BoxProps,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getInvitationsForUser } from "../../DataAccess/invitations";
import {
  createRelationship,
  getRelationshipsForUser,
} from "../../DataAccess/relationships";
import { useAppSelector } from "../../app/hooks";
import { updateInvitationsState } from "../../features/invitations/invitationsSlice";
import { updateRelationshipsState } from "../../features/relationships/relationshipsSlice";
import { selectUserState } from "../../features/user/userSlice";
import { affiliate } from "../../types/affiliate";
import { createToast } from "../../utils/toastHelper";
import { CreateSupportTicket } from "../ui/CreateSupportTicket";
import { CustomToast } from "../ui/CustomToast";
import Loading from "../ui/Loading";

interface ExtendRelationshipProps extends BoxProps {
  affiliate: affiliate;
  type?: string;
}

const ExtendRelationship: React.FC<ExtendRelationshipProps> = ({
  affiliate,
  type,
}) => {
  const { user } = useAppSelector(selectUserState);
  const toast = useToast();
  const dispatch = useDispatch();
  const {
    control: controlBasic,
    handleSubmit: handleSubmitBasic,
    reset: resetBasic,
    formState: { isSubmitting: isSubmittingBasic, isValid: isValidBasic },
  } = useForm({
    defaultValues: {
      recipientName: "",
      recipientEmailAddress: "",
      routineDays: affiliate.data.trial.routineDays,
      understandingDays: affiliate.data.trial.understandingDays,
      upgradeToPremiumMailingList: "",
    },
    mode: "all",
  });

  if (!user) {
    return <Loading message={"Loading User"} />;
  }

  const handleBasic = async (data: any): Promise<void> => {
    toast.closeAll();
    try {
      // Do some stuff
      await createRelationship(
        data.recipientName,
        data.recipientEmailAddress,
        data.routineDays,
        data.understandingDays,
        data.upgradeToPremiumMailingList
      );
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Add / Extend Relationship"}
            status={"Success"}
            toast={toast}
            toastId={props.id}
          >
            <Text>Relationship extended for {data.recipientName}</Text>
          </CustomToast>
        );
      });
      // Update the list
      const updatedResponseR = await getRelationshipsForUser(user._id);
      const updatedResponseI = await getInvitationsForUser(user._id);
      dispatch(updateRelationshipsState(updatedResponseR));
      dispatch(updateInvitationsState(updatedResponseI));
      resetBasic();
    } catch (error: any) {
      const realError = error as Error;
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Add / Extend Relationship"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>
              <Text>{realError.message}</Text>
            </Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
    }
  };

  return (
    <Grid
      templateColumns="repeat(2, 1fr)"
      columnGap={5}
      rowGap={1}
      mb={10}
      as="form"
      onSubmit={handleSubmitBasic(handleBasic)}
      w="full"
      alignItems="flex-start"
    >
      <FormControl>
        <FormLabel>Email Address</FormLabel>
        <Controller
          control={controlBasic}
          rules={{ required: true }}
          name="recipientEmailAddress"
          render={({ field: { ref, ...restField } }) => (
            <Input {...restField} placeholder="Email Address" />
          )}
        />
        <Text ml={1} mt={1} color={"gray"} fontSize={"xs"}>
          This is the email address the account will be created with. The user
          will not be able to change it at signup, although it is possible to
          change it subsequently.
        </Text>
      </FormControl>
      <FormControl>
        <FormLabel>First Name</FormLabel>
        <Controller
          control={controlBasic}
          rules={{ required: true }}
          name="recipientName"
          render={({ field: { ref, ...restField } }) => (
            <Input {...restField} placeholder="First Name" />
          )}
        />
        <Text ml={1} mt={1} color={"gray"} fontSize={"xs"}>
          First name is used as a greeting in any email sent.
        </Text>
      </FormControl>
      <FormControl>
        <FormLabel>Routine Trial Length</FormLabel>
        <Controller
          control={controlBasic}
          rules={{ required: "Duration is required" }}
          name="routineDays"
          render={({ field: { ref, ...restField } }) => (
            <InputGroup size="md">
              <InputRightElement color="gray" mr={12}>
                Routine
              </InputRightElement>
              <NumberInput
                {...restField}
                min={0}
                max={90}
                step={1}
                width="100%"
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </InputGroup>
          )}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Understanding Trial Length</FormLabel>
        <Controller
          control={controlBasic}
          rules={{ required: "Duration is required" }}
          name="understandingDays"
          render={({ field: { ref, ...restField } }) => (
            <InputGroup size="md">
              <InputRightElement color="gray" mr={70}>
                Understanding
              </InputRightElement>
              <NumberInput
                {...restField}
                min={0}
                max={365}
                step={1}
                width="100%"
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </InputGroup>
          )}
        />
      </FormControl>
      <GridItem colSpan={2}>
        <Text ml={1} mt={1} color={"gray"} fontSize={"xs"}>
          The number of days 'Routine' and 'Understanding' access you wish to
          give. Flow is x days 'Routine' then x days 'Understanding' then free
          access to 'Guidance'. You do not have to give access to any or all
          levels ... a standard signup will get 7 days 'Routine' and then free
          access to 'Guidance'. Maximum of {affiliate.data.trial.maxRoutineDays}{" "}
          days 'Routine', {affiliate.data.trial.maxUnderstandingDays} days
          'Understanding' and 'Guidance' is free forever. The total duration of
          a trial period will be capped at {affiliate.data.trial.maxTotalDays}{" "}
          days with 'Understanding' being shortened as required.
        </Text>
      </GridItem>
      <GridItem colSpan={2} mt={3}>
        <Button
          isDisabled={!isValidBasic}
          isLoading={isSubmittingBasic}
          type="submit"
          w={"full"}
        >
          Add
        </Button>
      </GridItem>
    </Grid>
  );
};

export { ExtendRelationship };
