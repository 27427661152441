import {
  Box,
  BoxProps,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { TrainingTimelineUnitSelector } from "../ui/DropDowns";

interface createTrainingTimelineQuestions {
  type: string | null;
  subType: string | null;
  distance: number | null;
  unit: string | null;
  name: string | null;
  lane: number | null;
  activitiesPerWeek: number | null;
  date?: string | null;
}

interface DistanceSelectorProps extends BoxProps {
  createQuestions: createTrainingTimelineQuestions;
  distanceInput: number;
  unitInput: string;
  setDistanceInput: any;
  setUnitInput: any;
}

const DistanceSelector: React.FC<DistanceSelectorProps> = ({
  createQuestions,
  distanceInput,
  unitInput,
  setDistanceInput,
  setUnitInput,
}) => {
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );
  const selectedBoxColorMode = useColorModeValue(
    "ssNeonOrangeLight",
    "ssNeonOrangeDark"
  );
  const [customDistance, setCustomDistance] = useState<boolean>(false);

  const isSelected = (distance: number, unit: string): string => {
    if (distanceInput === distance && unitInput === unit) {
      return selectedBoxColorMode;
    }
    return boxColorMode;
  };

  const getMin = (unit: string): number => {
    switch (unit) {
      case "m":
        return 400;
      case "km":
        return 0.4;
      case "y":
        return 400;
      case "mile":
        return 1;
      default:
        return 1;
    }
  };

  const getStep = (unit: string): number => {
    switch (unit) {
      case "m":
        return 100;
      case "km":
        return 0.1;
      case "y":
        return 100;
      case "mile":
        return 0.5;
      default:
        return 0.5;
    }
  };

  let distances: any[] = [];
  if (createQuestions.subType === "Triathlon") {
    distances = [
      [400, "m", "Super Sprint"],
      [750, "m", "Sprint"],
      [1.5, "km", "Olympic"],
      [1.9, "km", "70.3 (Half Iron)"],
      [3.8, "km", "140.6 (Iron)"],
    ];
  } else if (createQuestions.subType === "Open") {
    distances = [
      [1, "km", "1km"],
      [1.25, "km", "1.25km"],
      [1.5, "km", "1.5km"],
      [1, "mile", "1mile"],
      [2.5, "km", "2.5 m"],
      [5, "km", "5km"],
      [10, "km", "10km"],
    ];
  } else if (createQuestions.subType === "Pool") {
    distances = [
      [400, "m", "400m"],
      [800, "m", "800m"],
      [1500, "m", "1500m"],
      [200, "y", "200y"],
      [400, "y", "400y"],
      [800, "y", "800y"],
      [1500, "y", "1500y"],
      [1, "mile", "1 mile"],
    ];
  }

  return (
    <>
      <SimpleGrid columns={[1, 1, 5, 5]} spacing={3} w="full">
        {distances.map((distance: any) => {
          return (
            <Box
              key={distance[0] + distance[1]}
              bg={boxColorMode}
              borderWidth={3}
              borderColor={isSelected(distance[0], distance[1])}
              borderRadius={5}
              px={5}
              py={2}
              cursor="pointer"
              onClick={() => {
                setCustomDistance(false);
                setDistanceInput(distance[0]);
                setUnitInput(distance[1]);
              }}
            >
              <Text fontSize="lg">
                <b>
                  {distance[0]}
                  {distance[1]}
                </b>
              </Text>
              <Text color="gray">{distance[2]}</Text>
            </Box>
          );
        })}
        <Box
          key="custom"
          bg={boxColorMode}
          borderRadius={5}
          px={5}
          py={2}
          cursor="pointer"
          onClick={() => {
            setCustomDistance(true);
          }}
        >
          <Text fontSize="lg">
            <b>Other</b>
          </Text>
          <Text color="gray">Please Enter Below</Text>
        </Box>
      </SimpleGrid>
      <Box display={customDistance ? "block" : "none"} py={5}>
        <Text mb={2}>
          <b>Custom Distance</b>
        </Text>
        <HStack>
          <NumberInput
            value={distanceInput}
            placeholder="Distance"
            onChange={(_: string, valueAsNumber: number) => {
              setDistanceInput(valueAsNumber);
            }}
            min={getMin(unitInput)}
            step={getStep(unitInput)}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <TrainingTimelineUnitSelector
            value={unitInput}
            onChange={(event: any) => {
              setUnitInput(event.target.value);
            }}
          />
        </HStack>
      </Box>
    </>
  );
};

export { DistanceSelector };
