import {
  Box,
  Button,
  Center,
  GridItem,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { ForgottenPassword } from "../../components/auth/ForgottenPassword";
import { BaseLayout } from "../../components/layouts/BaseLayout";
import { Footer } from "../../components/layouts/Footer";
import { selectUserState } from "../../features/user/userSlice";
import { ProgressPage } from "../progress/Progress";

const ForgottenPasswordPage = () => {
  const { user } = useAppSelector(selectUserState);

  const [smallBreakPoint] = useMediaQuery("(max-width: 1024px)");
  const [midBreakPoint] = useMediaQuery("(max-width: 1280px)");

  const navigate = useNavigate();

  if (user) {
    return (
      <BaseLayout>
        <ProgressPage />
      </BaseLayout>
    );
  }

  if (smallBreakPoint || midBreakPoint) {
    return (
      <VStack pb={75}>
        <Image
          src="https://images.ctfassets.net/50b15ahactsg/1geExdwrEKi3rxDxvWByc1/fcc5dc4fea404d365d8f54bf5abcb0ee/Artboard_1.png"
          height={125}
          my={3}
        />
        <Heading size={"xl"} textAlign={"center"}>
          Great Swim Smooth coaching
          <br /> is just a click away!
        </Heading>
        <SimpleGrid columns={1} columnGap={10} w={"90%"} mt={-2}>
          <VStack alignItems={"flex-start"} w={"full"}>
            <HStack alignItems={"flex-start"} w={"full"}>
              <VStack alignItems={"flex-start"} w={"full"}>
                <Heading as={"h2"} size={"md"}>
                  Forgotten Password
                </Heading>
                <Text>Forgotten your password? No sweat, it happens 😀.</Text>
                <Text>
                  Enter your email address and if we find you account we'll send
                  you further instructions. Emails may take up to 5 minutes to
                  arrive, please try and resist the urge to request another
                  reset too quickly!
                </Text>
              </VStack>
            </HStack>
            <Box mt={5} w={"full"}>
              <ForgottenPassword />
            </Box>
            <Center w={"full"} my={5}>
              <Button mr={2} onClick={() => navigate("/", { replace: false })}>
                Don't have an account? Create one here.
              </Button>
              <Button ml={2} onClick={() => navigate("/", { replace: false })}>
                Remembered your password? Login here.
              </Button>
            </Center>
          </VStack>

          <VStack
            backgroundImage={"/marketing/marketingSwimmerPhone.jpg"}
            backgroundPosition={"left"}
            backgroundSize={"cover"}
            borderTopLeftRadius={20}
            borderBottomLeftRadius={20}
            minHeight={700}
          ></VStack>
        </SimpleGrid>
        <Footer />
      </VStack>
    );
  }

  return (
    <VStack pb={75}>
      <SimpleGrid w={"full"} columns={7} mb={3}>
        <GridItem colSpan={2} alignContent={"center"}>
          <Image
            src="https://images.ctfassets.net/50b15ahactsg/1geExdwrEKi3rxDxvWByc1/fcc5dc4fea404d365d8f54bf5abcb0ee/Artboard_1.png"
            height={125}
            my={3}
          />
        </GridItem>
        <GridItem colSpan={3} textAlign={"center"} alignContent={"center"}>
          <Heading size={"xl"}>
            Great Swim Smooth coaching
            <br /> is just a click away!
          </Heading>
        </GridItem>
        <GridItem
          colSpan={2}
          textAlign={"right"}
          alignContent={"center"}
          mr={10}
        >
          <Button onClick={() => navigate("/", { replace: false })}>
            Don't have an account? Create one here.
          </Button>
          <Button onClick={() => navigate("/", { replace: false })}>
            Remembered your password? Login here.
          </Button>
        </GridItem>
      </SimpleGrid>
      <SimpleGrid columns={3} columnGap={10} w={"full"} mt={-2}>
        <VStack
          backgroundImage={"/marketing/marketingPaulSwimmer.jpg"}
          backgroundPosition={"right"}
          backgroundSize={"cover"}
          borderTopRightRadius={20}
          borderBottomRightRadius={20}
          minHeight={700}
        ></VStack>
        <VStack alignItems={"flex-start"} w={"full"}>
          <HStack alignItems={"flex-start"} w={"full"}>
            <VStack alignItems={"flex-start"} w={"full"}>
              <Heading as={"h2"} size={"md"}>
                Forgotten Password
              </Heading>
              <Text>Forgotten your password? No sweat, it happens 😀.</Text>
              <Text>
                Enter your email address and if we find you account we'll send
                you further instructions. Emails may take up to 5 minutes to
                arrive, please try and resist the urge to request another reset
                too quickly!
              </Text>
            </VStack>
          </HStack>
          <Box mt={5} w={"full"}>
            <ForgottenPassword />
          </Box>
        </VStack>
        <VStack
          backgroundImage={"/marketing/marketingSwimmerPhone.jpg"}
          backgroundPosition={"left"}
          backgroundSize={"cover"}
          borderTopLeftRadius={20}
          borderBottomLeftRadius={20}
          minHeight={700}
        ></VStack>
      </SimpleGrid>
      <Footer />
    </VStack>
  );
};

export { ForgottenPasswordPage };
