import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  Heading,
  Icon,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { isDate } from "date-fns";
import clonedeep from "lodash.clonedeep";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import {
  createTrainingTimeline,
  getTrainingTimelineCurrent,
} from "../../DataAccess/trainingTimelines";
import { useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import {
  selectTrainingTimelineState,
  updateTrainingTimelineState,
} from "../../features/trainingTimeline/trainingTimelineSlice";
import { createTrainingTimelineQuestions } from "../../types/trainingTimeline";
import { checkRole } from "../../utils/authHelper";
import { formatDate } from "../../utils/dateHelper";
import { NotAuthorised } from "../generic/NotAuthorised";
import LoadingMulti from "../ui/LoadingMulti";
import { ActivitiesPerWeekSelector } from "./ActivitiesPerWeekSelector";
import { DistanceSelector } from "./DistanceSelector";
import { LaneSelector } from "./LaneSelector";
import { TypeSelector } from "./TypeSelector";

const CreateTrainingTimeline = () => {
  const { access: accessToken } = useAppSelector(selectAuthState);
  const [loadingTrainingTimeline, setLoadingTrainingTimeline] =
    useState<boolean>(true);
  const [createQuestions, setCreateQuestions] =
    useState<createTrainingTimelineQuestions>({
      type: null,
      subType: null,
      distance: null,
      unit: null,
      name: null,
      lane: null,
      activitiesPerWeek: null,
      date: null,
    });
  const [typeInput, setTypeInput] = useState<string>("");
  const [subTypeInput, setSubTypeInput] = useState<string>("");
  const [distanceInput, setDistanceInput] = useState<number>(0);
  const [unitInput, setUnitInput] = useState<string>("");
  const [laneInput, setLaneInput] = useState<number>(3);
  const [activitiesPerWeekInput, setActivitiesPerWeekInput] =
    useState<number>(3);
  const [nameInput, setNameInput] = useState<string>("");
  const min = new Date();
  min.setDate(min.getDate() + 21);
  const [dateInput, setDateInput] = useState<Date | null>(null);
  const { trainingTimeline, lastUpdated: lastUpdatedTrainingTimeline } =
    useAppSelector(selectTrainingTimelineState);
  const dispatch = useDispatch();

  useEffect(() => {
    const getTrainingTimelineLocal = async () => {
      const ttl = await getTrainingTimelineCurrent();
      dispatch(updateTrainingTimelineState(ttl));
      setLoadingTrainingTimeline(false);
    };
    if (lastUpdatedTrainingTimeline) {
      const test = new Date(lastUpdatedTrainingTimeline).getTime();
      const anHourAgo = new Date().getTime() - 1000 * 60 * 60;
      if (!trainingTimeline || anHourAgo > test) {
        getTrainingTimelineLocal();
      } else {
        setLoadingTrainingTimeline(false);
      }
    } else {
      getTrainingTimelineLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingTrainingTimeline) {
    return (
      <LoadingMulti
        messages={[
          "Working out the plan overview",
          "Calculating weeks",
          "Choosing sessions",
          "Considering events",
          "Finalising the plan",
        ]}
      />
    );
  }

  if (!checkRole(accessToken, "create", "ttl")) {
    return (
      <VStack w="full">
        <Heading as="h2" size="xl">
          Training Plans
        </Heading>
        <NotAuthorised size={"full"} functionText={"Dynamic Training Plans"} />
      </VStack>
    );
  }

  const typeInSentence = (type: string, subType: string): string => {
    if (type === "Event") {
      if (subType === "Triathlon") {
        return "triathlon event";
      } else if (subType === "Open") {
        return "open water event";
      } else if (subType === "Pool") {
        return "pool event";
      }
    } else if (type === "Distance") {
      if (subType === "Open") {
        return "open water";
      } else if (subType === "Pool") {
        return "pool";
      }
    } else if (type === "Group") {
      return "open water event";
    }
    return subType.toLocaleLowerCase();
  };

  const laneInSentence = (lane: number): string => {
    switch (lane) {
      case 0:
        return "Getting Started";
      case 1:
        return "Beginner";
      case 2:
        return "Intermediate";
      case 3:
      case 4:
        return "Experienced";
      case 5:
      case 6:
        return "Advanced";
      default:
        return "";
    }
  };

  const setQuestionsAll = (
    type: string | null,
    subType: string | null,
    distance: number | null,
    unit: string | null,
    lane: number | null,
    activitiesPerWeek: number | null,
    date: Date | string | null,
    name: string | null
  ) => {
    const updatedState = clonedeep(createQuestions);
    updatedState.type = type;
    updatedState.subType = subType;
    updatedState.distance = distance;
    updatedState.unit = unit;
    updatedState.lane = lane;
    updatedState.activitiesPerWeek = activitiesPerWeek;
    if (date && isDate(date) && typeof date !== "string") {
      updatedState.date = date.toISOString();
    } else if (typeof date === "string") {
      updatedState.date = date;
    } else {
      updatedState.date = "*NODATE";
    }
    updatedState.name = name;
    setCreateQuestions(updatedState);
  };

  const setQuestionsTypeAndSubType = (
    type: string | null,
    subType: string | null
  ) => {
    const updatedState = clonedeep(createQuestions);
    updatedState.type = type;
    updatedState.subType = subType;
    setCreateQuestions(updatedState);
  };

  const setQuestionsDistanceAndUnit = (
    distance: number | null,
    unit: string | null
  ) => {
    const updatedState = clonedeep(createQuestions);
    updatedState.distance = distance;
    updatedState.unit = unit;
    setCreateQuestions(updatedState);
  };

  const setQuestionsLane = (lane: number | null) => {
    const updatedState = clonedeep(createQuestions);
    updatedState.lane = lane;
    setCreateQuestions(updatedState);
  };

  const setQuestionsActivitiesPerWeek = (activitiesPerWeek: number | null) => {
    const updatedState = clonedeep(createQuestions);
    updatedState.activitiesPerWeek = activitiesPerWeek;
    setCreateQuestions(updatedState);
  };

  const setQuestionsDate = (date: Date | null) => {
    const updatedState = clonedeep(createQuestions);
    if (date) {
      updatedState.date = date.toISOString();
    } else {
      updatedState.date = date;
    }
    setCreateQuestions(updatedState);
  };

  const setQuestionsName = (name: string | null) => {
    const updatedState = clonedeep(createQuestions);
    updatedState.name = name;
    setCreateQuestions(updatedState);
  };

  const createTrainingTimelineHandler = async () => {
    try {
      setLoadingTrainingTimeline(true);
      const ttlResponse = await createTrainingTimeline(createQuestions);
      if (ttlResponse) {
        dispatch(updateTrainingTimelineState(ttlResponse));
      }
      setLoadingTrainingTimeline(false);
    } catch (error) {
      setLoadingTrainingTimeline(false);
    }
  };

  // Step One - Type and Sub Type
  if (createQuestions.type === null || createQuestions.subType === null) {
    return (
      <VStack w="full">
        <Heading as="h2" size="xl">
          Training Plans
        </Heading>
        <Text>
          Achieve your goals with tailored swim sessions for you each week
        </Text>
        <TypeSelector
          typeInput={typeInput}
          setTypeInput={setTypeInput}
          subTypeInput={subTypeInput}
          setSubTypeInput={setSubTypeInput}
        />
        <HStack w="full" pt={5}>
          <Box w="full">{/* <!-- Spanner --> */}</Box>
          <Button
            w={250}
            onClick={() => {
              if (typeInput === "Group" && subTypeInput === "Rottnest2025") {
                setQuestionsAll(
                  typeInput,
                  subTypeInput,
                  19.7,
                  "km",
                  4,
                  5,
                  new Date("2025-02-22"),
                  "Rottnest Channel Swim 2025"
                );
              } else if (typeInput === "Group" && subTypeInput === "Basics") {
                setQuestionsAll(
                  "Group",
                  "Basics",
                  100,
                  "m",
                  0,
                  null,
                  null,
                  null
                );
              } else {
                setQuestionsTypeAndSubType(typeInput, subTypeInput);
              }
            }}
            isDisabled={
              typeInput === null ||
              typeInput === "" ||
              subTypeInput === null ||
              subTypeInput === ""
            }
          >
            Next &gt;&gt;
          </Button>
        </HStack>
      </VStack>
    );
  }

  // Step Two - Distance and Unit
  if (createQuestions.distance === null || createQuestions.unit === null) {
    return (
      <VStack w="full" alignItems="flex-start">
        <Heading as="h5" size="md">
          First up, what's the{" "}
          {typeInSentence(createQuestions.type, createQuestions.subType)}{" "}
          distance?
        </Heading>
        <Text>
          If you've got more than one coming up, focus on the major event.
        </Text>
        <DistanceSelector
          createQuestions={createQuestions}
          distanceInput={distanceInput}
          unitInput={unitInput}
          setDistanceInput={setDistanceInput}
          setUnitInput={setUnitInput}
        />
        <HStack w="full" pt={5}>
          <Button
            w={200}
            variant="warning"
            onClick={() => {
              setQuestionsTypeAndSubType(null, null);
            }}
          >
            &lt;&lt; Back
          </Button>
          <Box w="full">{/* <!-- Spanner --> */}</Box>
          <Button
            w={250}
            onClick={() => {
              if (distanceInput && unitInput) {
                setQuestionsDistanceAndUnit(distanceInput, unitInput);
              }
            }}
            isDisabled={
              distanceInput === null ||
              distanceInput === 0 ||
              unitInput === null ||
              unitInput === ""
            }
          >
            Next &gt;&gt;
          </Button>
        </HStack>
      </VStack>
    );
  }

  // Step Three - Lane
  if (createQuestions.lane === null) {
    return (
      <VStack w="full" alignItems="flex-start">
        <Heading as="h5" size="md">
          Choose your level?
        </Heading>
        <Text>How does a typical swim session look for you?</Text>
        <LaneSelector laneInput={laneInput} setLaneInput={setLaneInput} />
        <HStack w="full" pt={5}>
          <Button
            w={200}
            variant="warning"
            onClick={() => {
              setQuestionsDistanceAndUnit(null, null);
            }}
          >
            &lt;&lt; Back
          </Button>
          <Box w="full">{/* <!-- Spanner --> */}</Box>
          <Button
            w={250}
            onClick={() => {
              setQuestionsLane(laneInput);
            }}
            isDisabled={laneInput === null}
          >
            Next &gt;&gt;
          </Button>
        </HStack>
      </VStack>
    );
  }

  // Step Four - Activities Per Week
  if (createQuestions.activitiesPerWeek === null) {
    return (
      <VStack w="full" alignItems="flex-start">
        <Heading as="h5" size="md">
          How many times can you realistically swim each week?
        </Heading>
        {createQuestions.type === "Group" &&
          createQuestions.subType === "Basics" && (
            <Text>
              This will depend on when you can get in the pool and how long you
              can comfortably swim for. Sessions range from 900m to 2000m,
              you'll be able to shorten them if you require.
            </Text>
          )}
        {createQuestions.type !== "Group" &&
          createQuestions.subType !== "Basics" && (
            <Text>
              You can always adjust this each week but we'll plan for the base
              number of sessions.
            </Text>
          )}
        <ActivitiesPerWeekSelector
          type={createQuestions.type}
          subType={createQuestions.subType}
          activitiesPerWeekInput={activitiesPerWeekInput}
          setActivitiesPerWeekInput={setActivitiesPerWeekInput}
        />
        <HStack w="full" pt={5}>
          <Button
            w={200}
            variant="warning"
            onClick={() => {
              if (
                createQuestions.type === "Group" &&
                createQuestions.subType === "Basics"
              ) {
                setQuestionsAll(null, null, null, null, null, null, null, null);
              } else {
                setQuestionsLane(null);
              }
            }}
          >
            &lt;&lt; Back
          </Button>
          <Box w="full">{/* <!-- Spanner --> */}</Box>
          <Button
            w={250}
            onClick={() => {
              if (
                createQuestions.type === "Group" &&
                createQuestions.subType === "Basics"
              ) {
                setQuestionsAll(
                  createQuestions.type,
                  createQuestions.subType,
                  createQuestions.distance,
                  createQuestions.unit,
                  createQuestions.lane,
                  activitiesPerWeekInput,
                  "*NODATE",
                  null
                );
              } else {
                setQuestionsActivitiesPerWeek(activitiesPerWeekInput);
              }
            }}
            isDisabled={
              activitiesPerWeekInput === null || activitiesPerWeekInput === 0
            }
          >
            Next &gt;&gt;
          </Button>
        </HStack>
      </VStack>
    );
  }

  // Step Five - Date
  if (createQuestions.date === null && createQuestions.type === "Event") {
    const now = new Date();
    let warningDate = new Date();
    let warningWeeks = 3;
    if (createQuestions.subType === "Triathlon") {
      if (createQuestions.unit === "m" || createQuestions.unit === "y") {
        if (createQuestions.distance <= 750) {
          warningWeeks = 6;
        } else if (createQuestions.distance <= 1900) {
          warningWeeks = 8;
        } else {
          warningWeeks = 12;
        }
      } else if (createQuestions.unit === "km") {
        if (createQuestions.distance <= 0.75) {
          warningWeeks = 6;
        } else if (createQuestions.distance <= 1.9) {
          warningWeeks = 8;
        } else {
          warningWeeks = 12;
        }
      } else if (createQuestions.unit === "miles") {
        if (createQuestions.distance <= 1) {
          warningWeeks = 6;
        } else if (createQuestions.distance <= 1.2) {
          warningWeeks = 8;
        } else {
          warningWeeks = 12;
        }
      }
    } else if (createQuestions.subType === "Pool") {
      if (createQuestions.unit === "m" || createQuestions.unit === "y") {
        if (createQuestions.distance <= 800) {
          warningWeeks = 5;
        } else {
          warningWeeks = 8;
        }
      } else if (createQuestions.unit === "km") {
        if (createQuestions.distance <= 0.8) {
          warningWeeks = 5;
        } else {
          warningWeeks = 8;
        }
      } else if (createQuestions.unit === "miles") {
        if (createQuestions.distance <= 0.5) {
          warningWeeks = 5;
        } else {
          warningWeeks = 8;
        }
      }
    } else if (createQuestions.subType === "Open") {
      if (createQuestions.unit === "m" || createQuestions.unit === "y") {
        if (createQuestions.distance <= 1600) {
          warningWeeks = 6;
        } else if (createQuestions.distance <= 2500) {
          warningWeeks = 8;
        } else {
          warningWeeks = 12;
        }
      } else if (createQuestions.unit === "km") {
        if (createQuestions.distance <= 1.6) {
          warningWeeks = 6;
        } else if (createQuestions.distance <= 2.5) {
          warningWeeks = 8;
        } else {
          warningWeeks = 12;
        }
      } else if (createQuestions.unit === "miles") {
        if (createQuestions.distance <= 1) {
          warningWeeks = 6;
        } else if (createQuestions.distance <= 1.55) {
          warningWeeks = 8;
        } else {
          warningWeeks = 12;
        }
      }
    }
    warningDate.setDate(warningDate.getDate() + warningWeeks * 7);
    const msInWeek = 1000 * 60 * 60 * 24 * 7;
    const weeks = Math.round(
      Math.abs(
        (dateInput ? dateInput.getTime() : warningDate.getTime()) -
          now.getTime()
      ) / msInWeek
    );
    return (
      <VStack w="full" alignItems="flex-start">
        <Heading as="h5" size="md">
          When is the event?
        </Heading>
        <DatePicker
          selected={dateInput ? dateInput : warningDate}
          onChange={(date: Date) => {
            setDateInput(date);
          }}
          minDate={min}
          dateFormat="do MMMM yyyy"
          calendarStartDay={1}
          customInput={<Input />}
          withPortal
          disabledKeyboardNavigation
        />
        {weeks >= warningWeeks && (
          <HStack>
            <Icon
              height={30}
              width={30}
              mr={1}
              as={CheckCircleIcon}
              color="darkGreen"
            />
            <Text>
              We recommend {warningWeeks} weeks training to prepare well for a{" "}
              {createQuestions.distance}
              {createQuestions.unit}{" "}
              {typeInSentence(createQuestions.type, createQuestions.subType)}.
              The date you have selected is {weeks} weeks away so you have
              plenty of time to train well.
            </Text>
          </HStack>
        )}
        {weeks < warningWeeks && (
          <HStack>
            <Icon
              height={30}
              width={30}
              mr={1}
              as={WarningIcon}
              color="darkRed"
            />
            <Text>
              We recommend {warningWeeks} weeks training to prepare well for a{" "}
              {createQuestions.distance}
              {createQuestions.unit}{" "}
              {typeInSentence(createQuestions.type, createQuestions.subType)}.
              The date you have selected is {weeks} weeks away which means that
              you may struggle to be ready in time for your event.
            </Text>
          </HStack>
        )}
        <HStack w="full" pt={5}>
          <Button
            w={200}
            variant="warning"
            onClick={() => {
              setQuestionsActivitiesPerWeek(null);
            }}
          >
            &lt;&lt; Back
          </Button>
          <Box w="full">{/* <!-- Spanner --> */}</Box>
          <Button
            w={250}
            onClick={() => {
              setQuestionsDate(dateInput);
            }}
            isDisabled={dateInput === null}
          >
            Next &gt;&gt;
          </Button>
        </HStack>
      </VStack>
    );
  }

  // Step Six - Name
  if (createQuestions.name === null) {
    return (
      <VStack w="full" alignItems="flex-start">
        <Heading as="h5" size="md">
          Finally, what shall we call your{" "}
          {typeInSentence(createQuestions.type, createQuestions.subType)}{" "}
          training?
        </Heading>
        <Text>
          This could be the name of the event, what you want to achieve or maybe
          just something to motivate you.
        </Text>
        <Input
          id="createQuestionsName"
          value={nameInput}
          size="lg"
          placeholder="Name"
          onChange={(event: any) => {
            setNameInput(event.target.value);
          }}
        />
        <HStack w="full" pt={5}>
          <Button
            w={200}
            variant="warning"
            onClick={() => {
              if (createQuestions.date) {
                setQuestionsDate(null);
              } else {
                setQuestionsActivitiesPerWeek(null);
              }
            }}
          >
            &lt;&lt; Back
          </Button>
          <Box w="full">{/* <!-- Spanner --> */}</Box>
          <Button
            w={250}
            onClick={() => {
              if (nameInput) {
                setQuestionsName(nameInput);
              }
            }}
            isDisabled={nameInput === null || nameInput === ""}
          >
            Next &gt;&gt;
          </Button>
        </HStack>
      </VStack>
    );
  }

  return (
    <VStack w="full" alignItems="flex-start">
      <Heading as="h2" size="xl">
        Nearly there ...
      </Heading>
      <Text>
        Here are the answers to our questions. Please check them over and if you
        are happy then click 'Create Training Plan' to get started.
      </Text>
      <Text>
        <b>Plan Name: </b>
        {createQuestions.name}
      </Text>
      {createQuestions.subType !== "Rottnest2025" &&
        createQuestions.subType !== "Basics" && (
          <Text>
            <b>Type: </b>
            {createQuestions.type} / {createQuestions.subType}
          </Text>
        )}
      {createQuestions.subType === "Basics" && (
        <Text>
          <b>Type: </b>
          Swim Development Plan
        </Text>
      )}
      {createQuestions.subType !== "Rottnest2025" &&
        createQuestions.subType !== "Basics" && (
          <Text>
            <b>Distance: </b>
            {createQuestions.distance}
            {createQuestions.unit}
          </Text>
        )}
      {createQuestions.subType !== "Rottnest2025" &&
        createQuestions.subType !== "Basics" && (
          <Text>
            <b>Level: </b>
            {laneInSentence(createQuestions.lane)}
          </Text>
        )}
      {createQuestions.subType !== "Rottnest2025" && (
        <Text>
          <b>Activities per week: </b>
          {createQuestions.activitiesPerWeek}
        </Text>
      )}
      {createQuestions.date && createQuestions.date !== "*NODATE" && (
        <Text>
          <b>Event Date: </b>
          {formatDate(createQuestions.date)}
        </Text>
      )}
      <HStack w="full" pt={5}>
        <Button
          w={200}
          variant="warning"
          onClick={() => {
            if (
              createQuestions.type === "Group" &&
              createQuestions.subType === "Rottnest2025"
            ) {
              setQuestionsAll(null, null, null, null, null, null, null, null);
            } else if (
              createQuestions.type === "Group" &&
              createQuestions.subType === "Basics"
            ) {
              setQuestionsAll("Group", "Basics", 100, "m", 0, null, null, null);
            } else {
              setQuestionsName(null);
            }
          }}
        >
          &lt;&lt; Back
        </Button>
        <Box w="full">{/* <!-- Spanner --> */}</Box>
        <Button
          w={300}
          variant="success"
          onClick={createTrainingTimelineHandler}
        >
          Create Training Plan
        </Button>
      </HStack>
    </VStack>
  );
};

export { CreateTrainingTimeline };
