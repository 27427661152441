import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { login } from "../../DataAccess/oauth";
import { updateAuthState } from "../../features/auth/authSlice";
import { PasswordInput } from "../ui/FormControls";
import Loading from "../ui/Loading";

const LoginForm = (props: any) => {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    control: controlLogin,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      hToken: "",
    },
    mode: "all",
  });

  const handleLogin = async (data: any): Promise<void> => {
    try {
      setInProgress(true);
      const loginResponse = await login(data.email, data.password, data.hToken);
      // Decode access token to get user id

      // Write tokens and user state
      dispatch(
        updateAuthState({
          access: loginResponse.access_token,
          refresh: loginResponse.refresh_token,
          admin: null,
          userId: loginResponse.userId,
        })
      );
      setInProgress(false);
      // Success - redirect to dashboard
      if (
        props.requestedLocation &&
        props.requestedLocation.pathname &&
        props.requestedLocation.pathname !== "/" &&
        props.requestedLocation.pathname !== "/login"
      ) {
        navigate(props.requestedLocation.pathname, { replace: false });
      } else {
        navigate("/progress", { replace: false });
      }
    } catch (error: any) {
      if (error.response) {
        setError(
          error.response.data.messages[0].message.user
            ? error.response.data.messages[0].message.user
            : "Unable to login, please contact support."
        );
        setInProgress(false);
      } else {
        setError(
          error.message
            ? error.message
            : error
            ? error
            : "Unable to login, please contact support."
        );
        setInProgress(false);
      }
    }
  };

  if (inProgress) {
    return (
      <VStack w="full">
        <Loading message="Logging In" />
      </VStack>
    );
  }

  return (
    <VStack
      as="form"
      onSubmit={handleSubmit(handleLogin)}
      w="full"
      p={5}
      spacing={3}
      alignItems="flex-start"
    >
      <FormControl pb={1}>
        <FormLabel>
          Email Address
          {errors.email && (
            <span className="formError">{errors.email.message}</span>
          )}
        </FormLabel>
        <Controller
          control={controlLogin}
          rules={{ required: "Email address is required" }}
          name="email"
          render={({ field: { ref, ...restField } }) => (
            <Input autoComplete="username" {...restField} placeholder="" />
          )}
        />
      </FormControl>
      <FormControl pb={1}>
        <FormLabel>
          Password
          {errors.password && (
            <span className="formError">{errors.password.message}</span>
          )}
        </FormLabel>
        <Controller
          control={controlLogin}
          rules={{ required: "Password is required" }}
          name="password"
          render={({ field: { ref, ...restField } }) => (
            <PasswordInput autoComplete="current-password" {...restField} />
          )}
        />
      </FormControl>
      {error && <Text color="red">{error}</Text>}
      <Button
        isDisabled={!isDirty || !isValid}
        isLoading={isSubmitting}
        type="submit"
      >
        Log In
      </Button>
      <HStack w="full">
        <Button
          variant="link"
          onClick={() => navigate("/password/forgot", { replace: false })}
        >
          Forgotten Password? Reset it here.
        </Button>
      </HStack>
    </VStack>
  );
};

export { LoginForm };
