import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { Guidance } from "../../components/progress/Guidance";
import { Routine } from "../../components/progress/Routine";
import { Ultimate } from "../../components/progress/Ultimate";
import { Understanding } from "../../components/progress/Understanding";
import Loading from "../../components/ui/Loading";
import { selectAuthState } from "../../features/auth/authSlice";
import { selectUserState } from "../../features/user/userSlice";
import { checkRole, getLevel } from "../../utils/authHelper";

const ProgressPage = () => {
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const [level, setLevel] = useState<string>();
  const { access: accessToken } = useAppSelector(selectAuthState);
  const { user } = useAppSelector(selectUserState);
  const navigate = useNavigate();

  useEffect(() => {
    const breadcrumbLinks = [{ href: "/progress", title: "Progress" }];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accessToken) {
      setLevel(getLevel(accessToken));
    }
  }, [accessToken]);

  if (!user) {
    return <Loading message="Loading User" />;
  }

  return (
    <VStack w="full">
      <Heading as="h2" size="xl">
        Hey {user ? user.data.basic.firstName : null}, welcome to Swim Smooth!
      </Heading>
      <Flex fontSize="xs" w="full">
        <Box pt={3}>
          <Text>&nbsp;</Text>
        </Box>
        <Spacer />
        <HStack>
          {/* <Button variant="success" isLoading={refreshing}>
            <Icon as={IoMdRefresh} />
          </Button> */}
          {checkRole(accessToken, "create", "activity") && (
            <Button
              onClick={() => {
                navigate("/activities/create", { replace: false });
              }}
            >
              Create Activity
            </Button>
          )}
        </HStack>
      </Flex>
      {level === "Ultimate" && <Ultimate />}
      {level === "Routine" && <Routine />}
      {level === "Understanding" && <Understanding />}
      {level === "Guidance" && <Guidance />}
    </VStack>
  );
};

export { ProgressPage };
