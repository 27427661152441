import {
  Box,
  Button,
  Divider,
  GridItem,
  HStack,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Text,
  Tooltip,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { GiPathDistance } from "react-icons/gi";
import { IoFlame, IoTimerOutline } from "react-icons/io5";
import { MdModelTraining } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import { wbssSession } from "../../types/session";
import { checkLevelInArray, getLevel } from "../../utils/authHelper";
import { formatDate } from "../../utils/dateHelper";
import Loading from "../ui/Loading";

interface WorldsBiggestSwimSquadSessionProps {
  wbssSession: wbssSession;
}

const WorldsBiggestSwimSquadSession: React.FC<
  WorldsBiggestSwimSquadSessionProps
> = ({ wbssSession }) => {
  const { access: accessToken } = useAppSelector(selectAuthState);
  const [loading, setLoading] = useState<boolean>(true);
  const [sessionAllowed, setSessionAllowed] = useState<boolean>(false);
  const iconSize = 5;
  const fontSize = "small";
  const intensityColorMode = useColorModeValue(
    "ssNeonOrangeDark",
    "ssNeonOrangeLight"
  );
  const dividerColorMode = useColorModeValue("ssPrimaryDark", "ssPrimaryLight");
  const navigate = useNavigate();

  useEffect(() => {
    if (wbssSession.sessionId) {
      if (wbssSession.levels.includes(getLevel(accessToken))) {
        setSessionAllowed(checkLevelInArray(accessToken, wbssSession.levels));
      } else {
        setSessionAllowed(false);
      }
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loading message="Loading The Worlds Biggest Swim Squad Session" />;
  }

  if (!sessionAllowed) {
    return (
      <HStack alignItems="flex-start" mb={5}>
        <VStack alignItems="flex-start" w="full">
          <Heading size="xs" mb={0} as="h4">
            {wbssSession.type}
          </Heading>
          <Heading size="md" mb={0} as="h3" noOfLines={1} mt={-2}>
            {wbssSession.session.name}
          </Heading>
          <Text fontSize="smaller">
            This '{wbssSession.type.toLowerCase()}' session that was swum on{" "}
            {formatDate(wbssSession.dateSwum)} in {wbssSession.location} and
            coached by {wbssSession.coach.name} requires a 'routine' or higher
            subscription.
          </Text>
          <Divider
            mt={1}
            size={"xl"}
            borderWidth={"1px"}
            borderColor={dividerColorMode}
          />
        </VStack>
      </HStack>
    );
  }

  return (
    <HStack alignItems="flex-start" mb={5}>
      <VStack alignItems="flex-start" w="full">
        <Heading size="xs" mb={0} as="h4" color={intensityColorMode}>
          {wbssSession.type}
        </Heading>
        <Heading size="md" mb={0} as="h3" noOfLines={1} mt={-2}>
          {wbssSession.session.name}
        </Heading>
        <HStack w="full">
          <Image
            borderRadius={10}
            h={70}
            w={70}
            mr={5}
            fit="cover"
            src={wbssSession.coach.image}
            fallbackSrc="https://images.ctfassets.net/50b15ahactsg/Al70qWgtjQvAjRifFlBIq/35448e67bbf63873261153bf268d3764/Paul_Squad.jpg"
            onClick={() => {
              navigate("/coaches/" + wbssSession.coach.shortCode, {
                replace: false,
              });
            }}
            cursor={"pointer"}
          />
          <SimpleGrid columns={[1, 1, 2, 2]} w="full">
            <Tooltip hasArrow label="Distance">
              <span>
                <Icon
                  h={iconSize}
                  w={iconSize}
                  color="grey"
                  viewBox="0 0 100 100"
                  mr={2}
                  as={GiPathDistance}
                />
                <Box
                  display="inline-block"
                  position="relative"
                  top="-6px"
                  fontSize={fontSize}
                >
                  {wbssSession.session.distance}
                </Box>
              </span>
            </Tooltip>
            <Tooltip hasArrow label="Expected Time">
              <span>
                <Icon
                  h={iconSize}
                  w={iconSize}
                  color="grey"
                  mr={2}
                  as={IoTimerOutline}
                />
                <Box
                  display="inline-block"
                  position="relative"
                  top="-6px"
                  fontSize={fontSize}
                >
                  {wbssSession.session.time}
                </Box>
              </span>
            </Tooltip>
            <Tooltip hasArrow label="Intensity">
              <span>
                <Icon
                  h={iconSize}
                  w={iconSize}
                  color="grey"
                  as={IoFlame}
                  fill={
                    wbssSession.session.intensity > 0
                      ? intensityColorMode
                      : "grey"
                  }
                />
                <Icon
                  h={iconSize}
                  w={iconSize}
                  color="grey"
                  as={IoFlame}
                  fill={
                    wbssSession.session.intensity > 1
                      ? intensityColorMode
                      : "grey"
                  }
                />
                <Icon
                  h={iconSize}
                  w={iconSize}
                  color="grey"
                  as={IoFlame}
                  fill={
                    wbssSession.session.intensity > 2
                      ? intensityColorMode
                      : "grey"
                  }
                />
                <Icon
                  h={iconSize}
                  w={iconSize}
                  color="grey"
                  as={IoFlame}
                  fill={
                    wbssSession.session.intensity > 3
                      ? intensityColorMode
                      : "grey"
                  }
                />
                <Icon
                  h={iconSize}
                  w={iconSize}
                  color="grey"
                  as={IoFlame}
                  fill={
                    wbssSession.session.intensity > 4
                      ? intensityColorMode
                      : "grey"
                  }
                />
              </span>
            </Tooltip>{" "}
            <Tooltip hasArrow label="sTSS">
              <span>
                <Icon
                  h={iconSize}
                  w={iconSize}
                  color="grey"
                  mr={2}
                  as={MdModelTraining}
                />
                <Box
                  display="inline-block"
                  position="relative"
                  top="-6px"
                  fontSize={fontSize}
                >
                  {wbssSession.session.stss
                    ? wbssSession.session.stss.toFixed(2)
                    : 0}
                </Box>
              </span>
            </Tooltip>
            <GridItem colSpan={2}>
              <SimpleGrid
                columns={[1, 1, 2, 2]}
                columnGap={5}
                rowGap={2}
                w="full"
              >
                <Button
                  onClick={() => {
                    navigate("/sessions/id/" + wbssSession.sessionId, {
                      replace: false,
                    });
                  }}
                  variant={"success"}
                  size={"xs"}
                  w={"full"}
                >
                  Swim It
                </Button>
                <Button
                  onClick={() => {
                    navigate("/coaches/" + wbssSession.coach.shortCode, {
                      replace: false,
                    });
                  }}
                  size={"xs"}
                  w={"full"}
                >
                  See 'Coach {wbssSession.coach.name}'
                </Button>
              </SimpleGrid>
            </GridItem>
          </SimpleGrid>
        </HStack>
        <Text fontSize="smaller">
          This '{wbssSession.type.toLowerCase()}' session was swum on{" "}
          {formatDate(wbssSession.dateSwum)} in {wbssSession.location} and
          coached by {wbssSession.coach.name}.
        </Text>
        <Divider
          mt={1}
          size={"xl"}
          borderWidth={"1px"}
          borderColor={dividerColorMode}
        />
      </VStack>
    </HStack>
  );
};

export { WorldsBiggestSwimSquadSession };
