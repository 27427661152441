import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
} from "@chakra-ui/react";
import { breadcrumbLink, breadcrumbProps } from "../../types/breadcrumb";

const SwimSmoothBreadcrumb = ({ links }: breadcrumbProps) => {
  if (links) {
    return (
      <Container maxW="container.xl" px={1} py={3}>
        <Breadcrumb width="full" alignItems="left">
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          {links.map((link: breadcrumbLink, index: number) => {
            return (
              <BreadcrumbItem key={"bc" + index}>
                <BreadcrumbLink href={link.href}>{link.title}</BreadcrumbLink>
              </BreadcrumbItem>
            );
          })}
        </Breadcrumb>
      </Container>
    );
  }

  return null;
};

export default SwimSmoothBreadcrumb;
