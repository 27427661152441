import {
  Avatar,
  BoxProps,
  Button,
  HStack,
  Td,
  Text,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { TiCancel } from "react-icons/ti";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteRelationship,
  getRelationshipsForUser,
} from "../../DataAccess/relationships";
import { useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import { updateRelationshipsState } from "../../features/relationships/relationshipsSlice";
import { selectUserState } from "../../features/user/userSlice";
import { relationship } from "../../types/relationship";
import { checkRole, swapToken } from "../../utils/authHelper";
import { formatDateShort } from "../../utils/dateHelper";
import { getGravatarUrl } from "../../utils/gravatarHelper";
import { createToast } from "../../utils/toastHelper";
import { CreateSupportTicket } from "../ui/CreateSupportTicket";
import { CustomToast } from "../ui/CustomToast";
import Loading from "../ui/Loading";
import { ChangeTrialMenu } from "./ChangeTrialMenu";

interface RelationshipItemProps extends BoxProps {
  relationship: relationship;
}

const RelationshipItem: React.FC<RelationshipItemProps> = ({
  relationship,
}) => {
  const { access: accessToken, refresh: refreshToken } =
    useAppSelector(selectAuthState);
  const { user } = useAppSelector(selectUserState);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [swapping, setSwapping] = useState<boolean>(false);
  const related =
    user?._id === relationship.data.parent.id
      ? relationship.data.child
      : relationship.data.parent;
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Work out some stuff
  let hasSubscription = false;
  if (
    related.extended?.data.billing.source === "Stripe" ||
    related.extended?.data.billing.source === "Apple"
  ) {
    hasSubscription = true;
  }
  let subscriptionExpired = false;
  if (hasSubscription) {
    const now = new Date().getTime();
    const endDate = new Date(
      related.extended!.data.billing.subscription!.currentPeriodEnd
    ).getTime();
    if (now > endDate) {
      subscriptionExpired = true;
    }
  }
  let hasTrial = false;
  if (
    related.extended?.data.billing.trial?.understandingEndDate ||
    related.extended?.data.billing.trial?.routineEndDate
  ) {
    hasTrial = true;
  }
  let routineTrialExpired = false;
  let understandingTrialExpired = false;
  if (hasTrial) {
    const now = new Date().getTime();
    const understandingEndDate = new Date(
      related.extended!.data.billing.trial!.understandingEndDate
    ).getTime();
    const routineEndDate = new Date(
      related.extended!.data.billing.trial!.routineEndDate
    ).getTime();
    if (now > routineEndDate) {
      routineTrialExpired = true;
    }
    if (now > understandingEndDate) {
      understandingTrialExpired = true;
    }
  }

  if (!user) {
    return <Loading message={"Loading User"} />;
  }

  const deleteRelationshipHandler = async (
    relationshipId: string,
    recipientName: string
  ) => {
    toast.closeAll();
    try {
      setIsDeleting(true);
      // Do some stuff
      await deleteRelationship(relationshipId);
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Delete Relationship"}
            status={"Success"}
            toast={toast}
            toastId={props.id}
          >
            <Text>Relationship changed for {recipientName}</Text>
          </CustomToast>
        );
      }); // Update the list
      const updatedResponse = await getRelationshipsForUser(user._id);
      dispatch(updateRelationshipsState(updatedResponse));
      setIsDeleting(false);
    } catch (error: any) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Delete Relationship"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>Unable to delete relationship, please contact support.</Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
      setIsDeleting(false);
    }
  };

  if (!related.extended) {
    return (
      <Tr key={relationship._id}>
        <Td>
          <Avatar
            size={"xs"}
            name={related.fullName}
            src={getGravatarUrl(related.emailAddress, 32)}
          />
        </Td>
        <Td> - </Td>
        <Td> - </Td>
        <Td>{related.emailAddress}</Td>
        <Td> - </Td>
        <Td> - </Td>
        <Td> - </Td>
        <Td isNumeric>
          <Button
            size={"xs"}
            variant={"warning"}
            isLoading={isDeleting}
            onClick={async () => {
              deleteRelationshipHandler(
                relationship._id,
                relationship.data.child.fullName
              );
            }}
          >
            Delete
          </Button>
        </Td>
      </Tr>
    );
  }

  return (
    <Tr key={relationship._id}>
      <Td>{related.extended?.data.basic.firstName}</Td>
      <Td>{related.extended?.data.basic.lastName}</Td>
      <Td>{related.emailAddress}</Td>
      <Td>
        {hasTrial && (
          <HStack>
            <Text>
              {related.extended!.data.billing.trial!.routineEndDate
                ? formatDateShort(
                    related.extended!.data.billing.trial!.routineEndDate
                  )
                : ""}
            </Text>
            {routineTrialExpired && <TiCancel color={"red"} />}
          </HStack>
        )}
      </Td>
      <Td>
        <ChangeTrialMenu relationship={relationship} level="routine" />
      </Td>
      <Td>
        {hasTrial && (
          <HStack>
            <Text>
              {related.extended!.data.billing.trial!.understandingEndDate
                ? formatDateShort(
                    related.extended!.data.billing.trial!.understandingEndDate
                  )
                : ""}
            </Text>
            {understandingTrialExpired && <TiCancel color={"red"} />}
          </HStack>
        )}
      </Td>
      <Td>
        <ChangeTrialMenu relationship={relationship} level="understanding" />
      </Td>
      <Td>
        {hasSubscription &&
          related.extended.data.billing.source +
            ": " +
            related.extended.data.billing.subscription!.level}
        {hasSubscription && (
          <HStack>
            <Text>
              {formatDateShort(
                related.extended.data.billing.subscription!.currentPeriodEnd
              )}
            </Text>
            {subscriptionExpired && <TiCancel color={"red"} />}
          </HStack>
        )}
      </Td>
      <Td isNumeric>
        {checkRole(accessToken, "*ALL", "superadmin") && (
          <Button
            variant={"success"}
            size={"xs"}
            mr={2}
            onClick={async () => {
              toast.closeAll();
              try {
                if (refreshToken) {
                  setSwapping(true);
                  await swapToken(dispatch, relationship.data.child.id);
                  navigate("/", { replace: false });
                } else {
                  setSwapping(false);
                  throw new Error("Unable to login as selected user");
                }
              } catch (error: any) {
                setSwapping(false);
                createToast(toast, (props: any) => {
                  return (
                    <CustomToast
                      title={"Login As"}
                      status={"Error"}
                      toast={toast}
                      toastId={props.id}
                    >
                      <Text>Unable to login as selected user</Text>
                    </CustomToast>
                  );
                });
              }
            }}
            isLoading={swapping}
          >
            Login As
          </Button>
        )}
        <Button
          size={"xs"}
          variant={"warning"}
          isLoading={isDeleting}
          onClick={async () => {
            deleteRelationshipHandler(
              relationship._id,
              relationship.data.child.fullName
            );
          }}
        >
          Delete
        </Button>
      </Td>
    </Tr>
  );
};

export { RelationshipItem };
