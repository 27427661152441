import {
  Avatar,
  AvatarBadge,
  Menu,
  MenuButton,
  MenuGroup,
  MenuList,
  Portal,
} from "@chakra-ui/react";
import { useColorMode, useColorModeValue } from "@chakra-ui/system";
import { CgDebug } from "react-icons/cg";
import { FaConnectdevelop, FaSwimmer, FaUserFriends } from "react-icons/fa";
import { FaUserGear } from "react-icons/fa6";
import { FiTarget } from "react-icons/fi";
import { IoIosFlame } from "react-icons/io";
import {
  MdAutorenew,
  MdDarkMode,
  MdFace,
  MdLightMode,
  MdLogin,
  MdLogout,
  MdOutlineWidgets,
  MdPassword,
  MdPrivacyTip,
  MdSettings,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import { selectUserState } from "../../features/user/userSlice";
import { backToAdmin, checkRole, clearState } from "../../utils/authHelper";
import { getGravatarUrl } from "../../utils/gravatarHelper";
import { BsrIcon, CssIcon } from "../ui/Icons";
import { SSSubMenuOption } from "./SSSubMenuOption";

const UserMenu = () => {
  const { access: accessToken, admin: adminToken } =
    useAppSelector(selectAuthState);
  const navigate = useNavigate();
  const { toggleColorMode } = useColorMode();
  const colorMode = useColorModeValue("light", "dark");
  const iconColour = useColorModeValue("ssPrimaryDark", "ssPrimaryLight");
  const iconText = useColorModeValue("ssPrimaryLight", "ssPrimaryDrk");
  const { user } = useAppSelector(selectUserState);
  const dispatch = useAppDispatch();

  const logout = () => {
    try {
      // Redux State
      clearState(dispatch, true);
      // Go back to landing page
      navigate("/", { replace: false });
    } catch (error) {}
  };

  const backToAdminAction = async () => {
    await backToAdmin(dispatch);
    navigate("/", { replace: false });
  };

  if (user) {
    let status: string = "red";
    if (
      user.data.billing.source === "Free" ||
      user.data.billing.subscriptionStatus === "Active" ||
      user.data.billing.trialStatus === "Active"
    ) {
      status = "green";
    } else if (user.data.billing.subscriptionStatus === "Cancelled") {
      status = "orange";
    } else {
      status = "red";
    }

    return (
      <Menu>
        <MenuButton aria-label="Options">
          <Avatar
            bg={iconColour}
            color={iconText}
            name={user.data.basic.fullName}
            src={getGravatarUrl(user.data.basic.emailAddress, 200)}
            size={["md", "md", "lg", "lg"]}
          >
            <AvatarBadge boxSize="0.75em" border="2px" bg={status} />
          </Avatar>
        </MenuButton>
        <Portal>
          <MenuList zIndex={20}>
            <MenuGroup title={user.data.basic.fullName}>
              <SSSubMenuOption
                icon={MdSettings}
                target="/account/settings/account"
                title="Account Settings"
              />
              <SSSubMenuOption
                icon={MdPrivacyTip}
                target="/account/settings/privacy"
                title="Privacy Settings"
              />
              <SSSubMenuOption
                icon={FaSwimmer}
                target="/account/settings/swimmer"
                title="Swimmer Settings"
              />
              <SSSubMenuOption
                icon={CssIcon}
                target="/css/history"
                title="Critical Swim Speed"
              />
              <SSSubMenuOption
                icon={BsrIcon}
                target="/bsr/history"
                title="Base Stroke Rate"
              />
              <SSSubMenuOption
                icon={FaConnectdevelop}
                target="/account/connections"
                title="Connections"
              />
              <SSSubMenuOption
                icon={MdAutorenew}
                target="/account/subscription"
                title="Subscription"
              />
            </MenuGroup>
            {checkRole(accessToken, "access", "adminTools") && (
              <MenuGroup title="Admin Tools">
                <SSSubMenuOption icon={CgDebug} target="/debug" title="Debug" />
                {checkRole(accessToken, "editor", "sessions") && (
                  <SSSubMenuOption
                    icon={FaUserGear}
                    target="/admin/user/lookup"
                    title="User Lookup"
                  />
                )}
                {checkRole(accessToken, "editor", "sessions") && (
                  <SSSubMenuOption
                    icon={IoIosFlame}
                    target="/admin/sessions/editor"
                    title="Session Editor"
                  />
                )}
                {checkRole(accessToken, "editor", "plans") && (
                  <SSSubMenuOption
                    icon={FiTarget}
                    target="/admin/plans/editor"
                    title="Plan Editor"
                  />
                )}
              </MenuGroup>
            )}
            {checkRole(accessToken, "access", "coachesTools") && (
              <MenuGroup title="Coaches Tools">
                {checkRole(accessToken, "affiliates", "widgets") && (
                  <SSSubMenuOption
                    icon={MdOutlineWidgets}
                    target="/affiliate/widgets"
                    title="Affiliate Widgets"
                  />
                )}
                {checkRole(accessToken, "affiliates", "relationships") && (
                  <SSSubMenuOption
                    icon={FaUserFriends}
                    target="/account/relationships"
                    title="Linked Users"
                  />
                )}
              </MenuGroup>
            )}
            <MenuGroup title="Actions">
              {colorMode === "light" && (
                <SSSubMenuOption
                  icon={MdDarkMode}
                  onClick={toggleColorMode}
                  title="Dark Mode"
                />
              )}
              {colorMode === "dark" && (
                <SSSubMenuOption
                  icon={MdLightMode}
                  onClick={toggleColorMode}
                  title="Light Mode"
                />
              )}
              {adminToken === null && (
                <SSSubMenuOption
                  icon={MdLogout}
                  onClick={logout}
                  title="Logout"
                />
              )}
              {adminToken !== null && (
                <SSSubMenuOption
                  icon={MdLogout}
                  onClick={backToAdminAction}
                  title="Logout (To Admin)"
                />
              )}
            </MenuGroup>
          </MenuList>
        </Portal>
      </Menu>
    );
  }

  return (
    <Menu>
      <MenuButton aria-label="Options">
        <Avatar
          bg={iconColour}
          color={iconText}
          name=""
          src=""
          size={["md", "md", "lg", "lg"]}
        />
      </MenuButton>
      <Portal>
        <MenuList zIndex={20}>
          <SSSubMenuOption
            icon={MdFace}
            target="/account/create"
            title="Create Account"
          />
          <SSSubMenuOption
            icon={MdPassword}
            target="/password/forgot"
            title="Forgotten Password"
          />
          <MenuGroup title="Actions">
            {colorMode === "light" && (
              <SSSubMenuOption
                icon={MdDarkMode}
                onClick={toggleColorMode}
                title="Dark Mode"
              />
            )}
            {colorMode === "dark" && (
              <SSSubMenuOption
                icon={MdLightMode}
                onClick={toggleColorMode}
                title="Light Mode"
              />
            )}
            <SSSubMenuOption icon={MdLogin} target="/" title="Login" />
          </MenuGroup>
        </MenuList>
      </Portal>
    </Menu>
  );
};

export { UserMenu };
