import {
  Box,
  Button,
  GridItem,
  Heading,
  HStack,
  Icon,
  Image,
  ListItem,
  SimpleGrid,
  Text,
  UnorderedList,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IoMdRefresh } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { LastWeek } from "../../components/trainingTimeline/LastWeek";
import { NextWeek } from "../../components/trainingTimeline/NextWeek";
import { ThisWeek } from "../../components/trainingTimeline/ThisWeek";
import FilledBar from "../../components/ui/FilledBar";
import Loading from "../../components/ui/Loading";
import {
  getTrainingTimelineCurrent,
  progressTrainingTimelineById,
  slideTrainingTimelineById,
} from "../../DataAccess/trainingTimelines";
import { selectAuthState } from "../../features/auth/authSlice";
import {
  selectTrainingTimelineState,
  updateTrainingTimelineState,
} from "../../features/trainingTimeline/trainingTimelineSlice";
import { selectUserState } from "../../features/user/userSlice";
import { checkRole, getLevel } from "../../utils/authHelper";
import { NotAuthorised } from "../generic/NotAuthorised";
import { CreateTrainingTimeline } from "./CreateTrainingTimeline";

const ViewTrainingTimeline = () => {
  const { access: accessToken } = useAppSelector(selectAuthState);
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );
  const [loadingTrainingTimeline, setLoadingTrainingTimeline] =
    useState<boolean>(true);
  const { trainingTimeline, lastUpdated: lastUpdatedTrainingTimeline } =
    useAppSelector(selectTrainingTimelineState);
  const { user } = useAppSelector(selectUserState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getTrainingTimelineLocal = async () => {
      try {
        const ttl = await getTrainingTimelineCurrent();
        dispatch(updateTrainingTimelineState(ttl));
        setLoadingTrainingTimeline(false);
      } catch (error) {
        dispatch(updateTrainingTimelineState(null));
        setLoadingTrainingTimeline(false);
      }
    };
    if (lastUpdatedTrainingTimeline) {
      const test = new Date(lastUpdatedTrainingTimeline).getTime();
      const anHourAgo = new Date().getTime() - 1000 * 60 * 60;
      if (!trainingTimeline || anHourAgo > test) {
        getTrainingTimelineLocal();
      } else {
        setLoadingTrainingTimeline(false);
      }
    } else {
      getTrainingTimelineLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const refreshTrainingTimeline = async () => {
    setLoadingTrainingTimeline(true);
    try {
      const ttl = await getTrainingTimelineCurrent();
      dispatch(updateTrainingTimelineState(ttl));
    } catch (error) {
      dispatch(updateTrainingTimelineState(null));
    }
    setLoadingTrainingTimeline(false);
  };

  const progressTrainingTimeline = async () => {
    setLoadingTrainingTimeline(true);
    if (trainingTimeline) {
      if (trainingTimeline.data.status === "Active") {
        const ttl = await progressTrainingTimelineById(trainingTimeline._id);
        dispatch(updateTrainingTimelineState(ttl));
      }
    }
    setLoadingTrainingTimeline(false);
  };

  const slideTrainingTimeline = async () => {
    setLoadingTrainingTimeline(true);
    if (trainingTimeline) {
      if (trainingTimeline.data.status === "Active") {
        const ttl = await slideTrainingTimelineById(trainingTimeline._id);
        dispatch(updateTrainingTimelineState(ttl));
      }
    }
    setLoadingTrainingTimeline(false);
  };

  if (loadingTrainingTimeline) {
    return <Loading message="Loading Training Timeline" />;
  }

  if (!user || !user) {
    return (
      <VStack w="full">
        <Heading as="h2" size="xl">
          Training Plans
        </Heading>
        <VStack w="full" alignItems="flex-start">
          <Text>
            Well now, this is embarrassing! It shouldn't be possible to get here{" "}
            <b>but</b> here we are. I suppose someone went to the trouble if
            writing this so it's obviously something that someone thought was
            possible even if a little inprobable!
          </Text>
        </VStack>
      </VStack>
    );
  }

  if (!checkRole(accessToken, "read", "ttl")) {
    if (trainingTimeline) {
      return (
        <VStack w="full" alignItems={"flex-start"}>
          <Heading as="h2" size="xl">
            Training
          </Heading>
          <Text>
            Your training plan '{trainingTimeline.data.name}' running{" "}
            {trainingTimeline.data.subTitle} is still here but training plan
            features require a 'Routine' or higher subscription (you are
            currently subscribed to '{getLevel(accessToken)}').
          </Text>
          <Text>
            You can check and upgrade your subscription level
            <Button
              variant={"link"}
              onClick={() => {
                navigate("/account/subscription");
              }}
            >
              here
            </Button>
            .
          </Text>
        </VStack>
      );
    }
    return (
      <VStack w="full">
        <Heading as="h2" size="xl">
          Training Plans
        </Heading>
        <NotAuthorised functionText={"Dynamic Training Plans"} size={"full"} />
      </VStack>
    );
  }

  if (!trainingTimeline) {
    return <CreateTrainingTimeline />;
  }

  // if (hamburgerMode) {
  //   return (
  //     <VStack w="full">
  //       <Heading as="h2" size="xl">
  //         {trainingTimeline.data.name}
  //       </Heading>
  //       <Text>
  //         {trainingTimeline.data.overview.currentWeek} :{" "}
  //         {trainingTimeline.data.overview.remainingText}
  //       </Text>
  //       {trainingTimeline.data.overview.totalWeeks > 0 &&
  //         trainingTimeline.data.overview.currentWeek >= 0 && (
  //           <Box mt={5} mb={5}>
  //             <FilledBar
  //               width={300}
  //               score={
  //                 trainingTimeline.data.overview.totalWeeks > 0
  //                   ? (trainingTimeline.data.overview.currentWeek /
  //                       trainingTimeline.data.overview.totalWeeks) *
  //                     100
  //                   : 100
  //               }
  //               max={100}
  //               showScore={false}
  //             />
  //           </Box>
  //         )}
  //       <HStack pt={1}>
  //         <Button
  //           w={150}
  //           onClick={() => {
  //             navigate("/training/settings", { replace: false });
  //           }}
  //         >
  //           Settings
  //         </Button>
  //         <Button
  //           variant="success"
  //           isLoading={loadingTrainingTimeline}
  //           onClick={refreshTrainingTimeline}
  //         >
  //           <Icon as={IoMdRefresh} style={{ fill: "white" }} color="white" />
  //         </Button>
  //       </HStack>
  //       <HStack>
  //         {checkRole(accessToken, "slide", "ttl") && (
  //           <Button
  //             w={150}
  //             variant="pink"
  //             isLoading={loadingTrainingTimeline}
  //             onClick={slideTrainingTimeline}
  //           >
  //             &lt;&lt; Slide
  //           </Button>
  //         )}
  //         {checkRole(accessToken, "progress", "ttl") && (
  //           <Button
  //             w={150}
  //             variant="pink"
  //             isLoading={loadingTrainingTimeline}
  //             onClick={progressTrainingTimeline}
  //           >
  //             Progress &gt;&gt;
  //           </Button>
  //         )}
  //       </HStack>
  //       <SimpleGrid columns={[1, 1, 3, 3]} w={"full"}>
  //         <LastWeek user={user} trainingTimeline={trainingTimeline} />
  //         <ThisWeek user={user} trainingTimeline={trainingTimeline} />
  //         <NextWeek
  //           user={user}
  //           trainingTimeline={trainingTimeline}
  //           setLoadingTrainingTimeline={setLoadingTrainingTimeline}
  //         />
  //       </SimpleGrid>
  //       <VStack w="full" alignItems="flex-start">
  //         <Box bg={boxColorMode} p={4} borderRadius={10} w="full" h="full">
  //           <VStack alignItems="flex-start" w="full" mb={2}>
  //             {trainingTimeline.data.overview.currentWeek === 1 && (
  //               <Heading as="h4" size="md">
  //                 Plan Overview
  //               </Heading>
  //             )}
  //             <UnorderedList w="full">
  //               {trainingTimeline.data.overview.breakdown.map(
  //                 (item: any, itemIndex: number) => {
  //                   if (item.blockType === "audit") {
  //                     return (
  //                       <ListItem key={itemIndex} mb={2} ml={10} color="gray">
  //                         <b>Audit</b>:<br />
  //                         <i>
  //                           {item.description.map(
  //                             (line: any, lineIndex: number) => {
  //                               return line.data;
  //                             }
  //                           )}
  //                         </i>
  //                       </ListItem>
  //                     );
  //                   }
  //                   return (
  //                     <ListItem key={itemIndex} mb={2} ml={10}>
  //                       <b>{item.title}</b>
  //                       <br />
  //                       {item.subTitle}
  //                       <br />
  //                       <i>
  //                         {item.description.map(
  //                           (line: any, lineIndex: number) => {
  //                             return line.data;
  //                           }
  //                         )}
  //                       </i>
  //                     </ListItem>
  //                   );
  //                 }
  //               )}
  //             </UnorderedList>
  //           </VStack>
  //         </Box>
  //         {trainingTimeline.type !== "*SPECIAL" && (
  //           <Box bg={boxColorMode} p={4} borderRadius={10} w="full" h="full">
  //             <VStack alignItems="flex-start" w="full" mb={2}>
  //               {trainingTimeline.data.overview.currentWeek === 1 && (
  //                 <Heading as="h4" size="md">
  //                   How It Works
  //                 </Heading>
  //               )}
  //               <UnorderedList>
  //                 <ListItem mb={2} ml={10}>
  //                   Your plan adapts each week depending on how you’re doing.
  //                 </ListItem>
  //                 <ListItem mb={2} ml={10}>
  //                   Adjust the number of sessions anytime if it’s too much or
  //                   too little.
  //                 </ListItem>
  //                 <ListItem mb={2} ml={10}>
  //                   Stick to the plan. For best results, stick to the
  //                   recommended sessions.
  //                 </ListItem>
  //                 <ListItem mb={2} ml={10}>
  //                   Each weekend your sessions for next week will be generated
  //                   based on your fitness, fatigue, training balance and plan
  //                   settings. If you've done extra activities these will be
  //                   taken into account as will doing less training than planned.
  //                 </ListItem>
  //               </UnorderedList>
  //             </VStack>
  //           </Box>
  //         )}
  //       </VStack>
  //     </VStack>
  //   );
  // }

  return (
    <VStack w="full">
      <Heading as="h2" size="xl">
        {trainingTimeline.data.name}
      </Heading>
      {trainingTimeline.data.weeks.thisWeek &&
        trainingTimeline.data.overview.currentWeek > 0 && (
          <Text>
            Week {trainingTimeline.data.overview.currentWeek} of{" "}
            {trainingTimeline.data.overview.totalWeeks}
          </Text>
        )}
      {trainingTimeline.data.weeks.thisWeek &&
        trainingTimeline.data.overview.currentWeek === 0 && (
          <Text>Your plan starts properly next week</Text>
        )}
      {!trainingTimeline.data.weeks.thisWeek &&
        trainingTimeline.data.overview.currentWeek <= 0 && (
          <Text>Your plan starts soon</Text>
        )}
      {!trainingTimeline.data.weeks.thisWeek &&
        trainingTimeline.data.overview.currentWeek >= 0 && (
          <Text>Error, contact support</Text>
        )}
      {trainingTimeline.data.overview.totalWeeks > 0 &&
        trainingTimeline.data.overview.currentWeek >= 0 && (
          <Box mt={5} mb={5}>
            <FilledBar
              width={800}
              score={
                trainingTimeline.data.overview.totalWeeks > 0
                  ? (trainingTimeline.data.overview.currentWeek /
                      trainingTimeline.data.overview.totalWeeks) *
                    100
                  : 100
              }
              max={100}
              showScore={false}
            />
          </Box>
        )}
      <HStack pt={1}>
        {checkRole(accessToken, "slide", "ttl") && (
          <Button
            w={150}
            variant="pink"
            isLoading={loadingTrainingTimeline}
            onClick={slideTrainingTimeline}
          >
            &lt;&lt; Slide
          </Button>
        )}
        <Button
          w={150}
          onClick={() => {
            navigate("/training/settings", { replace: false });
          }}
        >
          Settings
        </Button>
        <Button
          variant="success"
          isLoading={loadingTrainingTimeline}
          onClick={refreshTrainingTimeline}
        >
          <Icon as={IoMdRefresh} />
        </Button>
        {checkRole(accessToken, "progress", "ttl") && (
          <Button
            w={150}
            variant="pink"
            isLoading={loadingTrainingTimeline}
            onClick={progressTrainingTimeline}
          >
            Progress &gt;&gt;
          </Button>
        )}
      </HStack>
      {trainingTimeline.data.overview.currentWeek < 0 && (
        <HStack pt={2} w="full" alignItems="flex-start">
          <Box bg={boxColorMode} p={4} borderRadius={10} w="full" h="full">
            <Heading as="h4" size="md">
              Your plan starts in{" "}
              {Math.abs(trainingTimeline.data.overview.currentWeek)} weeks.
            </Heading>
            <Text pt={2}>
              Your '{trainingTimeline.data.name}' training plan starts in{" "}
              {Math.abs(trainingTimeline.data.overview.currentWeek)} weeks.
            </Text>
            <Text pt={2}>
              In the meantime, think of the period until you start as the
              opportunity to “train-to-train” and once we start the program
              you’ll then be “training-to-race” (or at least complete!) the
              event. We’d recommend spinning the Session Roulette wheel in this
              period from the Progress page to give you a couple of fun and
              unexpected sessions to try out. You can also get organised with a
              CSS Test (though one does feature in Week 1 anyway).
            </Text>
            <Text pt={2}>
              Check out your plan overview below which will help you understand
              how the {trainingTimeline.data.overview.totalWeeks} weeks of your
              plan are broken down and where you'll have A, B & C races.
            </Text>
          </Box>
        </HStack>
      )}
      {trainingTimeline.data.overview.currentWeek >= 0 && (
        <SimpleGrid
          pt={2}
          columns={[1, 1, 1, 4]}
          columnGap={2}
          rowGap={2}
          w="full"
          alignItems="flex-start"
        >
          <LastWeek user={user} trainingTimeline={trainingTimeline} />
          <GridItem colSpan={[1, 1, 1, 2]}>
            <ThisWeek user={user} trainingTimeline={trainingTimeline} />
          </GridItem>
          <NextWeek
            user={user}
            trainingTimeline={trainingTimeline}
            setLoadingTrainingTimeline={setLoadingTrainingTimeline}
          />
        </SimpleGrid>
      )}
      <SimpleGrid
        pt={2}
        columns={[1, 1, 1, 2]}
        columnGap={2}
        rowGap={2}
        w="full"
        alignItems="flex-start"
      >
        <Box bg={boxColorMode} p={4} borderRadius={10} w="full" h="full">
          <Heading as="h4" size="md">
            Plan Overview
          </Heading>
          <UnorderedList w="full">
            {trainingTimeline.data.overview.breakdown.map(
              (item: any, itemIndex: number) => {
                if (item.blockType === "audit") {
                  return (
                    <ListItem key={itemIndex} mb={2} ml={10} color="gray">
                      <b>Audit</b>:<br />
                      <i>
                        {item.description.map(
                          (line: any, lineIndex: number) => {
                            return line.data;
                          }
                        )}
                      </i>
                    </ListItem>
                  );
                }
                return (
                  <ListItem key={itemIndex} mb={2} ml={10}>
                    <b>{item.title}</b>
                    <br />
                    {item.subTitle}
                    <br />
                    <i>
                      {item.description.map((line: any, lineIndex: number) => {
                        return line.data;
                      })}
                    </i>
                  </ListItem>
                );
              }
            )}
          </UnorderedList>
        </Box>
        {trainingTimeline.type === "*SPECIAL" &&
          trainingTimeline.data.name === "Rottnest Channel Swim 2025" && (
            <Image
              style={{ cursor: "pointer" }}
              onClick={() => {
                window
                  .open(
                    "https://images.ctfassets.net/50b15ahactsg/7EWqAOZ75zU2pQc3t8M2DD/6768cd3746481b801109b1286902bbbc/RottoPlan2025VolumeOverview.png"
                  )
                  ?.focus();
              }}
              src={
                "https://images.ctfassets.net/50b15ahactsg/7EWqAOZ75zU2pQc3t8M2DD/6768cd3746481b801109b1286902bbbc/RottoPlan2025VolumeOverview.png?w=500"
              }
            />
          )}
        {trainingTimeline.type !== "*SPECIAL" && (
          <Box bg={boxColorMode} p={4} borderRadius={10} w="full" h="full">
            <VStack alignItems="flex-start" w="full" mb={2}>
              {trainingTimeline.data.overview.currentWeek === 1 && (
                <Heading as="h4" size="md">
                  How It Works
                </Heading>
              )}
              <UnorderedList>
                <ListItem mb={2} ml={10}>
                  Your plan adapts each week depending on how you’re doing.
                </ListItem>
                <ListItem mb={2} ml={10}>
                  Adjust the number of sessions anytime if it’s too much or too
                  little.
                </ListItem>
                <ListItem mb={2} ml={10}>
                  Stick to the plan. For best results, stick to the recommended
                  sessions.
                </ListItem>
                <ListItem mb={2} ml={10}>
                  Each weekend your sessions for next week will be generated
                  based on your fitness, fatigue, training balance and plan
                  settings. If you've done extra activities these will be taken
                  into account as will doing less training than planned.
                </ListItem>
              </UnorderedList>
            </VStack>
          </Box>
        )}
      </SimpleGrid>
    </VStack>
  );
};

export { ViewTrainingTimeline };
