import {
  Box,
  Menu,
  MenuButton,
  MenuGroup,
  MenuList,
  Portal,
} from "@chakra-ui/react";
import { FaSwimmer } from "react-icons/fa";
import { FiTarget } from "react-icons/fi";
import { GoGraph } from "react-icons/go";
import { ImStopwatch } from "react-icons/im";
import { IoIosFlame } from "react-icons/io";
import {
  MdFace,
  MdLogin,
  MdLogout,
  MdOutlineLocalLibrary,
  MdPassword,
} from "react-icons/md";
import { VscTools } from "react-icons/vsc";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import { selectUserState } from "../../features/user/userSlice";
import { backToAdmin, clearState } from "../../utils/authHelper";
import { SSHamburgerIcon } from "./SSHamburgerIcon";
import { SSSubMenuOption } from "./SSSubMenuOption";

const HamburgerMenu = (props: any) => {
  const { admin: adminToken } = useAppSelector(selectAuthState);
  const { user } = useAppSelector(selectUserState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    try {
      // Redux State
      clearState(dispatch, true);
      // Go back to landing page
      navigate("/", { replace: false });
    } catch (error) {}
  };

  const backToAdminAction = async () => {
    await backToAdmin(dispatch);
    navigate("/", { replace: false });
  };

  if (user) {
    return (
      <Menu>
        <MenuButton aria-label="Options">
          <SSHamburgerIcon />
        </MenuButton>
        <Portal>
          <MenuList zIndex={20}>
            <SSSubMenuOption
              icon={GoGraph}
              title="Progress"
              target="/progress"
            />
            <SSSubMenuOption
              icon={FiTarget}
              title="Training"
              target="/training"
            />
            <SSSubMenuOption
              icon={FaSwimmer}
              title="Activities"
              target="/activities"
            />
            <SSSubMenuOption
              icon={IoIosFlame}
              title="Sessions"
              target="/sessions"
            />
            <SSSubMenuOption
              icon={MdOutlineLocalLibrary}
              title="Library"
              target="/library"
            />
            <SSSubMenuOption
              icon={ImStopwatch}
              title="Coaches"
              target="/coaches"
            />
            <SSSubMenuOption icon={VscTools} title="Tools" target="/tools" />
            <MenuGroup title="Actions">
              {adminToken === null && (
                <SSSubMenuOption
                  icon={MdLogout}
                  onClick={logout}
                  title="Logout"
                />
              )}
              {adminToken !== null && (
                <SSSubMenuOption
                  icon={MdLogout}
                  onClick={backToAdminAction}
                  title="Logout (To Admin)"
                />
              )}
            </MenuGroup>
          </MenuList>
        </Portal>
      </Menu>
    );
  }

  return (
    <Menu>
      <MenuButton aria-label="Options">
        <SSHamburgerIcon />
      </MenuButton>
      <Portal>
        <MenuList zIndex={20}>
          <SSSubMenuOption
            icon={ImStopwatch}
            title="Coaches"
            target="/coaches"
          />
          <Box display="flex" alignItems="center" mr={5}>
            <SSSubMenuOption icon={VscTools} title="Tools" target="/tools" />
          </Box>
          <SSSubMenuOption
            icon={MdFace}
            title="Create Account"
            target="/account/create"
          />
          <SSSubMenuOption
            icon={MdPassword}
            target="/password/forgot"
            title="Forgotten Password"
          />
          <SSSubMenuOption icon={MdLogin} title="Login" target="/" />
        </MenuList>
      </Portal>
    </Menu>
  );
};

export { HamburgerMenu };
