import {
  Box,
  Button,
  FormControl,
  Grid,
  Heading,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import fileDownload from "js-file-download";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getAffiliateByUserId } from "../../DataAccess/affiliates";
import { getInvitationsForUser } from "../../DataAccess/invitations";
import { getRelationshipsForUser } from "../../DataAccess/relationships";
import { useAppSelector } from "../../app/hooks";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { NotAuthorised } from "../../components/generic/NotAuthorised";
import { ExtendRelationship } from "../../components/relationships/AddExtendRelationship";
import { AffiliateNotSetup } from "../../components/relationships/AffiliateNotSetup";
import { BulkExtendRelationship } from "../../components/relationships/BulkExtendRelationship";
import { LinkedUsers } from "../../components/relationships/Relationships";
import { RelationshipsInvitation } from "../../components/relationships/RelationshipsInvitation";
import Loading from "../../components/ui/Loading";
import LoadingMulti from "../../components/ui/LoadingMulti";
import {
  selectAffiliateState,
  updateAffiliateState,
} from "../../features/affiliate/affiliateSlice";
import { selectAuthState } from "../../features/auth/authSlice";
import {
  selectInvitationsState,
  updateInvitationsState,
} from "../../features/invitations/invitationsSlice";
import {
  selectRelationshipsState,
  updateRelationshipsState,
} from "../../features/relationships/relationshipsSlice";
import { selectUserState } from "../../features/user/userSlice";
import { breadcrumbLink } from "../../types/breadcrumb";
import { invitation } from "../../types/invitation";
import { relationship } from "../../types/relationship";
import { checkRole } from "../../utils/authHelper";

const RelationshipsPage = () => {
  const { access: accessToken } = useAppSelector(selectAuthState);
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const [loadingRelationships, setLoadingRelationships] =
    useState<boolean>(true);
  const [loadingInvitations, setLoadingInvitations] = useState<boolean>(true);
  const [loadingUser, setLoadingUser] = useState<boolean>(true);

  const { user } = useAppSelector(selectUserState);
  const { affiliate } = useAppSelector(selectAffiliateState);
  const { relationships } = useAppSelector(selectRelationshipsState);
  const { invitations } = useAppSelector(selectInvitationsState);
  const dispatch = useDispatch();

  const {
    control: controlBasic,
    watch: watchBasic,
    handleSubmit: handleSubmitBasic,
  } = useForm({
    defaultValues: {
      searchNameEmail: "",
    },
    mode: "all",
  });

  const handleBasic = async (data: any): Promise<void> => {
    console.log("Denada!");
  };

  const watchSearchNameEmail = watchBasic("searchNameEmail", "");

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [];
    breadcrumbLinks.push({ href: "/relationships", title: "Linked Users" });
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getAffiliateByUserIdLocal = async () => {
      try {
        if (user) {
          const response = await getAffiliateByUserId(user._id);
          dispatch(updateAffiliateState(response));
        }
        setLoadingRelationships(false);
      } catch (error) {
        setLoadingRelationships(false);
      }
    };
    getAffiliateByUserIdLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const getRelationshipForUserLocal = async () => {
      try {
        if (user) {
          const response = await getRelationshipsForUser(user._id);
          dispatch(updateRelationshipsState(response));
        }
        setLoadingUser(false);
      } catch (error) {
        setLoadingUser(false);
      }
    };
    getRelationshipForUserLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const getInvitationsForUserLocal = async () => {
      try {
        if (user) {
          const response = await getInvitationsForUser(user._id);
          dispatch(updateInvitationsState(response));
        }
        setLoadingInvitations(false);
      } catch (error) {
        setLoadingInvitations(false);
      }
    };
    getInvitationsForUserLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (!user) {
    return <Loading message={"Loading User"} />;
  }

  if (loadingRelationships || loadingUser || loadingInvitations) {
    return (
      <LoadingMulti
        messages={["Loading Affiliate", "Loading User", "Loading Affilates"]}
      />
    );
  }

  if (!checkRole(accessToken, "affiliates", "relationships")) {
    return <NotAuthorised functionText={"Linked Users"} size={"full"} />;
  }

  if (!affiliate) {
    return <AffiliateNotSetup />;
  }

  return (
    <VStack w="full">
      <Heading as="h2" size="xl" mb={4}>
        Linked Users
      </Heading>
      {checkRole(accessToken, "addExtend", "relationships") && (
        <Box w="full">
          <Heading mb={5} size="md">
            Search Linked Users & Invitations
          </Heading>
          <Grid
            templateColumns="repeat(1, 1fr)"
            gap={1}
            mb={10}
            as="form"
            onSubmit={handleSubmitBasic(handleBasic)}
            w="full"
            alignItems="flex-start"
          >
            <FormControl>
              <Controller
                control={controlBasic}
                rules={{ required: true }}
                name="searchNameEmail"
                render={({ field: { ref, ...restField } }) => (
                  <Input
                    {...restField}
                    placeholder="Search by Name or Email Address"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Box>
      )}
      {checkRole(accessToken, "addExtend", "relationships") && (
        <Box w="full">
          <Heading mb={5} size="md">
            Invitations
          </Heading>
          <RelationshipsInvitation
            invitations={invitations.filter((i: invitation) => {
              return (
                i.data.recipientEmail
                  .toLowerCase()
                  .indexOf(watchSearchNameEmail.toLowerCase()) > -1 ||
                i.data.recipientName
                  .toLowerCase()
                  .indexOf(watchSearchNameEmail.toLowerCase()) > -1
              );
            })}
          />
        </Box>
      )}
      {checkRole(accessToken, "addExtend", "relationships") && (
        <Box w="full">
          <Heading mb={5} size="md">
            Linked Users
          </Heading>
          <LinkedUsers
            affiliate={affiliate}
            relationships={relationships.filter((r: relationship) => {
              return (
                r.data.child.emailAddress
                  .toLowerCase()
                  .indexOf(watchSearchNameEmail.toLowerCase()) > -1 ||
                r.data.child.fullName
                  .toLowerCase()
                  .indexOf(watchSearchNameEmail.toLowerCase()) > -1
              );
            })}
          />
        </Box>
      )}
      {checkRole(accessToken, "addExtend", "relationships") && (
        <Box w="full">
          <Heading mb={2} size="md">
            Add Linked User
          </Heading>
          <Text mb={5}>
            If the user you are adding is not already linked to you they will
            recieve an email invitation to either signup or login to link. If
            the user is already linked to you then their trial will be changed.
          </Text>
          <ExtendRelationship affiliate={affiliate} />
        </Box>
      )}
      {checkRole(accessToken, "addBulk", "relationships") && (
        <Box w="full">
          <Heading mb={2} size="md">
            Bulk Add
          </Heading>
          <Text mb={5}>
            Bulk add users with a CSV upload. An example file can be found{" "}
            <Button
              variant={"link"}
              onClick={() => {
                const pdfData = new Blob(
                  [
                    `email,fnam,routine,understanding,mailing
bob@chickensdontfly.co.uk,Bob,90,,
kevin@chickensdontfly.co.uk,Kevin,60,,
stuart@chickensdontfly.co.uk,Stuart,30,,
may@chickensdontfly.co.uk,May,30,365,X
june@chickensdontfly.co.uk,June,30,90,X
stoobyjnr@chickensdontfly.co.uk,Stooby Jnr,30,30,X
storm@chickensdontfly.co.uk,Storm,30,7,X`,
                  ],
                  {
                    type: "text/csv",
                  }
                );
                fileDownload(pdfData, "relationships_bulk_import.csv");
              }}
            >
              here
            </Button>
            .
          </Text>
          <BulkExtendRelationship />
        </Box>
      )}
    </VStack>
  );
};

export { RelationshipsPage };
